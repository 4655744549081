import styled from "styled-components";
import EventsManage from "../components/EventManage";

const EventsAdd = () => {
  return <>
    <EventsManage />
  </>
};

export default EventsAdd;

const S = {};

S.Container = styled.div``;
