import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

const Dropzone = ({ onFilesChange, title = '', button = 'Importer mon fichier', multiple }) => {
  const [files, setFiles] = useState([]);

  // Get all files and store them in state
  const handleFilesChange = useCallback(
    (acceptedFiles) => {
      let updatedFiles = [...acceptedFiles];
      if (multiple) {
        updatedFiles = [...files, ...acceptedFiles];
      }
      setFiles(updatedFiles);
      onFilesChange(updatedFiles);
    },
    [files, onFilesChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple,
    onDrop: handleFilesChange,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'application/pdf': []
    }
  });

  // Remove file from state
  const removeFile = (e) => {
    const updatedFiles = files.filter((file) => file.path !== e);
    setFiles(updatedFiles);
    onFilesChange(updatedFiles);
  }

  // Files to display
  const fileItems = files.map((file) => (
    <S.File key={file.path}>
      <p className='body1'>{file.path}</p>
      <div onClick={() => removeFile(file.path)}>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z" /></svg>
      </div>
    </S.File>
  ));

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.Dropzone {...getRootProps()}>
      <input {...getInputProps()} />
      {title && <p>{title}</p>}
      <div className='supportedFiles'>Formats supportés : PDF, PNG, JPG</div>
      <S.Button>{button}</S.Button>
    </S.Dropzone>
    <S.Files>
      {fileItems}
    </S.Files>
  </S.Container>
};

const S = {};

S.Container = styled.div`
  width: 100%;
  box-sizing: border-box;
`

S.Dropzone = styled.div`
  border: 1px dashed var(--primary-color);
  width: 100%;
  padding: 32px;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  & p {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  & .supportedFiles {
    color: grey;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
`;

S.Button = styled.div`
display: flex;
padding: 12px 16px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background: var(--primary-color);
width: fit-content;
margin: auto;
cursor: pointer;

color: #FFF;
text-align: center;
font-size: 14px;
font-weight: 600;
line-height: 24px;
`

S.Files = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px;
`

S.File = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid grey;
  padding: 12px;
  
  & p {
    color:grey;
  }
  & div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 100%;

    & svg {
    width: 24px;
    height: 24px;
    fill: grey;
    }
  }
`

export default Dropzone;
