// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Api from '../contexts/Api';
import { prepareFormData } from '../contexts/Utils';
import Formfields from '../components/Formfields';
import { useNotification } from '../components/UseNotification';
import styled from 'styled-components';
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ForgotPassword = () => {
  const { notification } = useNotification()

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: "email", component: "text", name: "email", label: "Email" },
  ]

  // 
  // ─── HANDLE USER LOGIN ───────────────────────────────────────
  //
  const handleChange = async () => {
    const data = prepareFormData({ formId: 'loginForm' });

    const params = {
      data,
      endpoint: '/users/password/reset'
    }

    const resetPassword = await Api(params)

    notification({ variant: resetPassword.success ? 'success' : 'error', message: resetPassword.message })
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
      minHeight: '100vh'
    },
    form: {
      maxWidth: 500,
      margin: "auto",
      background: "#FFF",
      padding: 20,
      boxShadow: " 0 20px 27px 0 rgb(0 ,0, 0, 5%)",
      display: 'flex',
      flexWrap: 'wrap',
      gap: 20
    },
    titleContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    title: {
      marginTop: 0,
      color: 'var(--primary-color)'
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%'
    },
    register: {
      position: 'absolute',
      top: 20,
      right: 20,
      color: '#000',
      textDecoration: 'none'
    },
    forgotPassword: {
      textDecoration: 'none',
      color: '#000'
    }
  })
  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.FirstColumn>
      <S.Logo src='/assets/img/logo-main.png' alt='Logo Saint-Bernard' />
      <S.TitleLeft>ahhh... mot de passe oublié ?!?</S.TitleLeft>
    </S.FirstColumn>
    <S.SecondColumn>
      <S.Background src='/assets/img/background.png' alt='Background' />
      <S.Form name='loginForm'>
        <S.Title>Mot de passe oublié</S.Title>
        {formFields.map((el) => (<Formfields key={el.name} field={el} />))}
        <div className={classes.btnContainer}>
          <Button variant="contained" onClick={handleChange} autoFocus>Envoyer</Button>
        </div>
      </S.Form>
    </S.SecondColumn>
  </S.Container>
};

export default ForgotPassword;

let S = {};

S.Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;

  @media screen and (max-width:1300px) {
    grid-template-columns: 1fr;
  }
`;

S.FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  background: #15483c;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media screen and (max-width:1300px) {
    display: none;
  }
`

S.SecondColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

S.Background = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  inset: 0;
  z-index: -1;
`

S.Logo = styled.img`
  width: 50px;
  height: 50px;

  @media screen and (max-width:450px) {
    width: 150px;
  }
`;

S.Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 10px;
  max-width: 24rem;

  @media (min-width: 1024px) {
    padding: 2rem;
  }
`;

S.FormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`

S.TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

S.Title = styled.h3`
  margin-top: 0;
  color: var(--primary-color);
  @media screen and (max-width:450px) {
    font-size: 20px;
  }
`;

S.TitleLeft = styled.h1`
  color: #FFF;
`

S.Link = styled(Link)`
  color: black;
  text-decoration: none;
  font-size:15px;
  cursor: pointer;

  &:hover {
    color: var(--primary-color);
  }
`