// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNotification } from '../UseNotification';
import Api from '../../contexts/Api';
import { formatDate } from '../../contexts/Utils';
import styled from "styled-components";
import Button from '../BaseComponents/Button';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const MissionDetailDriver = (props) => {
  const { notification } = useNotification();
  const mission = props.mission;
  const [proposals, setProposals] = useState([]);
  const user_id = JSON.parse(localStorage.getItem('saint_bernard_auth')).user_id;

  // 
  // ─── GET DRIVERS PROPOSALS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const getUserProposal = await Api({ endpoint: `/missions/drivers_proposal?mission_id=${props.mission_id}&driver_id=${user_id}`, method: 'GET', });
      setProposals(getUserProposal.data);
    }

    fetchData();
  }, [props.mission_id])

  // Cancel proposal driver
  const handleCancelProposal = async (proposal_id) => {
    const cancelProposal = await Api({
      endpoint: `/missions/drivers_proposal`,
      method: 'PUT',
      data: { delete: true, id: proposal_id }
    });

    notification({ variant: cancelProposal.success ? 'success' : 'error', message: cancelProposal.success ? 'Proposition annulée' : 'Erreur lors de l\'annulation de la proposition' });

    // Remove proposal from list
    if (cancelProposal.success) {
      setProposals(proposals.filter(p => p.id !== proposal_id));
    }
  };

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return Boolean(proposals.length) && <S.Container>
    <h2>Mes propositions</h2>
    <S.TableContainer className="shadow-md rounded-md">
      <S.Table>
        <thead>
          <tr>
            <th>Récupération</th>
            <th>Livraison</th>
            <th>Retour</th>
            <th>Prioritaire</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {proposals.map((driver, index) => {
            return (
              <tr key={index}>
                <td>{formatDate({ time: driver.recovery_date, display: 'date' })}</td>
                <td>{formatDate({ time: driver.delivery_date, display: 'date' })}</td>
                <td>{driver.recovery_delivery_date ? formatDate({ time: driver.recovery_delivery_date, display: 'date' }) : 'Non'}</td>
                <td>{driver.urgent ? 'Oui' : 'Non'}</td>
                <S.Actions>
                  {mission.status < 4 && <Button variant="primary" className="shadow-md" onClick={() => handleCancelProposal(driver.id)}>Annuler ma proposition</Button>}
                </S.Actions>
              </tr>
            );
          })}
        </tbody>
      </S.Table>
    </S.TableContainer>
  </S.Container>
};

export default MissionDetailDriver;

const S = {};
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 20px;
`;

S.GridTable = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`;

S.TableContainer = styled.div`
  max-width: 100%;
  width: 100%;

  @media (max-width: 1300px) {
    overflow-x: scroll;
  }
`;

S.Table = styled.table`
  border-radius: 0.5rem;
  border: 0;
  border-collapse: collapse;
  width: 100%;

  thead {
    background: var(--card-light);
    color: var(--white);
    text-align: left;
  }

  thead > tr > th:first-child {
    border-top-left-radius: 0.5rem;
  }

  thead > tr > th:last-child {
    border-top-right-radius: 0.5rem;
  }

  tbody {
    background: var(--white);
    color: var(--text-color);
  }

  tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 0.5rem;
  }

  tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 0.5rem;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid var(--card-dark);
  }

  th,
  td {
    padding: 1rem;

    &:first-child {
      padding-left: 2rem;
    }

    &:last-child {
      padding-right: 2rem;
    }
  }

  th {
    padding: 1.25rem 1rem;
  }
`;

S.Actions = styled.td`
  display: flex;
  gap: 10px;
  align-items: center;

  & svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: var(--primary-color);
  }

  & .shadow-md {
    height: 2rem
  }
`;