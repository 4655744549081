// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../UseNotification';
import Api from '../../contexts/Api';
import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import { getValue, prepareFormData, formatDate } from '../../contexts/Utils';
import Formfields from '../Formfields';
import styled from "styled-components";
import Button from '../BaseComponents/Button';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const MissionDetailDrivers = (props) => {
  const [codeModalOpen, setCodeModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const { notification } = useNotification();
  const navigate = useNavigate();
  const mission = props.mission;
  const classes = useStyle();
  const [users, setUsers] = useState([])

  // 
  // ─── GET DRIVERS PROPOSALS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const getUsersViews = await Api({ endpoint: `/missions/drivers_proposal?mission_id=${props.mission_id}`, method: 'GET', });
      setUsers(getUsersViews.data);
    }

    fetchData();
  }, [props.mission_id])

  // 
  // ─── CHOOSE DRIVER ───────────────────────────────────────
  //
  const handleMissionDriver = async () => {
    const data = prepareFormData({ formId: 'code_form' });
    if (data.errors) {
      setErrors(data.errors);
      return;
    }

    const selectedProposal = users.filter((u) => u.id === selectedId)[0];

    let paramsData = {
      fields: {
        delivery_date: formatDate({ time: selectedProposal.delivery_date, display: 'mission' }),
        recovery_date: formatDate({ time: selectedProposal.recovery_date, display: 'mission' }),
        weproov_code: data.fields.weproov_code,
        driver_id: selectedProposal.user_id,
        urgent: selectedProposal.urgent
      },
      company_id: props.mission.company_id,
      select_driver: true,
      uuid: props.mission_id,
      from_detail: true,
    }

    if (selectedProposal.recovery_delivery_date) {
      paramsData.fields.recovery_delivery_date = formatDate({ time: selectedProposal.recovery_delivery_date, display: 'mission' })
    }

    if (props.mission.recovery) {
      paramsData.recovery_weproov_code = data.fields.recovery_weproov_code;
    }

    const params = { endpoint: `/missions`, method: 'PUT', data: paramsData };

    const createMission = await Api(params);
    notification({ variant: createMission.success ? 'success' : 'error', message: "Convoyeur sélectionné pour la mission" });
    navigate('/');
  }

  const goToDriverPage = (id) => {
    return navigate(`/utilisateurs/${id}`);
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return Boolean(users.length) && <S.Container>
    <Modal
      open={codeModalOpen}
      onClose={() => setCodeModalOpen(false)}
    >
      <Box className={classes.codeModal}>
        <h2>Code weproov</h2>
        <form id='code_form' className={classes.weproovModal}>
          <Formfields field={{ type: 'text', component: 'text', name: 'weproov_code', label: 'Code weproov', required: true, error: getValue(errors, ['weproov_code', 'message']) }} />
          {Boolean(props.mission.recovery) && <Formfields field={{ type: 'text', component: 'text', name: 'recovery_weproov_code', label: 'Code weproov reprise', required: true, error: getValue(errors, ['recovery_weproov_code', 'message']) }} />}
          <Button onClick={handleMissionDriver}> Valider le convoyeur</Button>
        </form>
      </Box>
    </Modal>
    <h2>{`${mission.status > 2 ? 'Historique des convoyeurs' : 'Convoyeurs disponibles pour la mission'}`}</h2>
    <S.TableContainer className="shadow-md rounded-md">
      <S.Table>
        <thead>
          <tr>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Email</th>
            <th>Récupération</th>
            <th>Livraison</th>
            {Boolean(props.mission.recovery) && <th>Retour</th>}
            <th>Prioritaire</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {users.map((driver, index) => {
            return (
              <S.Tr key={index} onClick={() => goToDriverPage(driver.user_id)}>
                <td>{driver.firstname}</td>
                <td>{driver.name}</td>
                <td>{driver.email}</td>
                <td>{formatDate({ time: driver.recovery_date, display: 'date' })}</td>
                <td>{formatDate({ time: driver.delivery_date, display: 'date' })}</td>
                {Boolean(props.mission.recovery) && < td > {driver.recovery_delivery_date ? formatDate({ time: driver.recovery_delivery_date, display: 'date' }) : ''}</td>}
                <td style={{ color: driver.urgent ? 'red' : '', fontWeight: driver.urgent ? 'bold' : 'initial' }}>{driver.urgent ? 'Oui' : 'Non'}</td>
                <S.Actions>
                  {mission.status <= 2 && <Button variant="primary" className="shadow-md" onClick={(e) => { e.stopPropagation(); setCodeModalOpen(true); setSelectedId(driver.id); }}>Choisir</Button>}
                </S.Actions>
              </S.Tr>
            );
          })}
        </tbody>
      </S.Table>
    </S.TableContainer>
  </S.Container >
};

export default MissionDetailDrivers;

//
// ─── INITIALISATION DES STYLES ───────────────────────────────────────
//
const useStyle = createUseStyles({
  container: {
    background: '#FFF',
    padding: 20,
    boxShadow: ' 0 20px 27px 0 rgb(0 ,0, 0, 5%)',
    marginTop: 20
  },
  driversContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  },
  driverCard: {
    display: 'flex',
    gap: 20,
    alignItems: 'center',
    '& > span:nth-child(5)': {
      textTransform: 'capitalize'
    }
  },
  codeModal: {
    position: 'absolute',
    background: '#fff',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '25px',
    width: '350px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',

    '& h2': {
      width: '100%',
      textAlign: 'center',
      marginBottom: "35px",
      marginTop: '0',
      alignSelf: "center"
    }
  },
  modalInput: {
    width: '150px',
    marginBottom: '10px'
  },
  weproovModal: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  },
  chooseDriver: {
    marginLeft: 'auto'
  }
});

const S = {};
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 20px;
`;

S.GridTable = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`;

S.Tr = styled.tr`
  cursor: pointer;
`;

S.TableContainer = styled.div`
  max-width: 100%;
  width: 100%;

  @media (max-width: 1300px) {
    overflow-x: scroll;
  }
`;

S.Table = styled.table`
  border-radius: 0.5rem;
  border: 0;
  border-collapse: collapse;
  width: 100%;

  thead {
    background: var(--card-light);
    color: var(--white);
    text-align: left;
  }

  thead > tr > th:first-child {
    border-top-left-radius: 0.5rem;
  }

  thead > tr > th:last-child {
    border-top-right-radius: 0.5rem;
  }

  tbody {
    background: var(--white);
    color: var(--text-color);
  }

  tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 0.5rem;
  }

  tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 0.5rem;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid var(--card-dark);
  }

  th,
  td {
    padding: 1rem;

    &:first-child {
      padding-left: 2rem;
    }

    &:last-child {
      padding-right: 2rem;
    }
  }

  th {
    padding: 1.25rem 1rem;
  }
`;

S.Actions = styled.td`
  display: flex;
  gap: 10px;
  align-items: center;

  & svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: var(--primary-color);
  }

  & .shadow-md {
    height: 2rem;
    z-index: 99;
  }
`;