// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import MissionsList from './MissionsList';
import EventsList from './EventsList';
import styled from 'styled-components';


// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Home = ({ role }) => {

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div>
    <S.Container>
      <S.Title>Convoyages</S.Title>
      <MissionsList role={role} search={true} />
    </S.Container>
    {role !== 'garage' && <S.Container>
      <S.Title>Événements</S.Title>
      <EventsList key='1' status='1' role={role} />
    </S.Container>}
  </div>
};

export default Home;

const S = {};

S.Container = styled.div`
  margin-bottom: 40px;
`

S.Title = styled.h2`
  margin-top: 0;
  font-size: 30px;
  text-decoration: underline;
`