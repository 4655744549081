import { useEffect, useRef } from 'react';
import { MissionEntities } from '../entities/missions_entities';

//
// ─── COPY AN OBJECT AND RETURN THE VALUE ───────────────────────────────────────
//
export const copyObject = (originalObject) => {
  return Object.assign({}, originalObject);
};

//
// ─── STATE DECLARATION ───────────────────────────────────────
//
export const getValue = (object, values) => {
  if (!object) {
    return undefined;
  }

  let objectValue = object;

  values.map((value) => {
    if (objectValue && objectValue.hasOwnProperty(value)) {
      objectValue = objectValue[value];
    } else {
      objectValue = undefined;
    }
  });

  return objectValue;
};

//
// ─── PREPARE FORM DATA BEFORE SUBMIT  ───────────────────────────────────────
//
export const prepareFormData = (opt) => {
  let data = {};
  let errors = {};
  let multipleDesc = {};

  Array.from(document.forms[opt.formId])
    .filter((e) => e.getAttribute('name'))
    .forEach((element) => {
      let fieldValue;
      if (element.type === 'radio') {
        if (element.checked) {
          fieldValue = { [element.name]: element.value };
        }
      } else if (element.type === 'checkbox') {
        if (element.required && !element.checked) {
          errors[element.name] = { error: true, message: 'Champ obligatoire' };
        } else {
          fieldValue = { [element.name]: element.checked ? true : false };
        }
      } else if (element.type === 'file') {
        fieldValue = { [element.name]: element.files[0] };
        if (!element.files[0] && element.required) {
          errors[element.name] = { error: true, message: 'Fichier manquant' };
        } else {
          fieldValue = { [element.name]: element.files[0] };
        }
      } else if (element.dataset.attribute !== 'multiple') {
        const checkField = validateFields({
          type: element.type,
          value: element.value,
          required: element.required,
        });
        if (checkField.error) {
          errors[element.name] = checkField;
        } else {
          fieldValue = { [element.name]: element.value };
        }
        // Traitement spécifique pour les formulaires multiples
      } else if (element.dataset.attribute === 'multiple' && element.value) {
        const elementId = parseInt(element.name.slice(-1));
        let name = element.name.includes('value') ? 'value' : 'label';
        multipleDesc[elementId] = {
          ...multipleDesc[elementId],
          ...{
            [name]: element.value,
          },
        };

        data.fields[element.dataset.name] = JSON.stringify(multipleDesc);
      }

      data.fields = {
        ...data.fields,
        ...fieldValue,
      };

      return data;
    });

  if (Object.keys(errors).length) {
    data.errors = errors;
  }

  return data;
};

//
// ─── FORM FIELD VALIDATION ───────────────────────────────────────
//
const validateFields = (opt) => {
  let result = {};

  if (!opt.value && opt.required) {
    result = { error: true, message: 'Champ obligatoire' };
  } else if (opt.type === 'email') {
    // let re = /^([a-zA-Z0-9-_.])+@\S+\.\S+$/;
    // if (!re.test(opt.value)) {
    //   result = { error: true, message: 'Email invalide' }
    // }
  }

  return result;
};

//
// ─── FORMAT TRAVEL DATA ───────────────────────────────────────
//
export const formatDistanceTime = (opt) => {
  if (opt.distance) {
    return `${Math.round(opt.distance)} km`;
  } else if (opt.duration) {
    const d = Number(opt.duration);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);

    var hDisplay = h > 0 ? h + ' h ' : '';
    var mDisplay = m > 0 ? m + ' min' : '';
    return hDisplay + mDisplay;
  }
};

//
// ─── FORMAT DATE ───────────────────────────────────────
//
export const formatDate = (opt) => {
  if (!opt.time) return '';

  let d = new Date(opt.time);

  if (opt.add_time) {
    d = new Date(d.getTime() + opt.add_time * 1000);
  }

  let data = {
    year: d.getFullYear(),
    month: d.getMonth() + 1,
    day: d.getDate(),
    hours: d.getHours(),
    minutes: d.getMinutes(),
    week_day: d.getDay(),
  };

  const monthLabels = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  const dayLabels = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

  if (opt.display === 'date_full') {
    data.month = monthLabels[data.month - 1];
    data.week_day = dayLabels[data.week_day];
  }

  Object.entries(data).map(([key, value]) => {
    if (
      (opt.display === 'date_full' && !['day', 'month'].includes(key)) ||
      opt.display !== 'date_full'
    ) {
      return (data[key] = value.toString().padStart(2, 0));
    }
  });

  let year = data.year;
  let month = data.month;
  let day = data.day;
  let hours = data.hours;
  let minutes = data.minutes;
  let week_day = data.week_day;

  let result;
  switch (opt.display) {
    case 'hour':
      result = `${hours}h${minutes}`;
      break;
    case 'mission':
      result = `${year}-${month}-${day}`;
      break;
    case 'date_full':
      result = capitalizeFirstLetter(`${week_day} ${day} ${month} ${year}`);
      break;
    case 'date':
      result = `${day}/${month}/${year}`;
      break;
    default:
      result = `${day}/${month}/${year} - ${hours}h${minutes}`;
      break;
  }

  return result;
};

//
// ─── CAPITALIZE FIRST LETTER ───────────────────────────────────────
//
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

//
// ─── FORMAT DATA ───────────────────────────────────────
//
export const formatData = (opt) => {
  // Assign data to object to avoid side effects
  let data = Object.assign({}, opt.data);

  if (Object.keys(data).length) {
    // Loop throug all elements to format them
    Object.entries(data).map(([key, value]) => {
      const entity = MissionEntities[key] || {};

      // Add suffix to value (€ to a price)
      if (entity.suffix && value) {
        data[key] = value + entity.suffix;
      }
      if (entity.type === 'boolean') {
        data[key] = value ? 'Oui' : 'Non';
      }
      return data;
    });
  }
  return data;
};

//
// ─── PRICE CALCULATE ───────────────────────────────────────
//
export const getPrice = (opt) => {
  let { vehicle_type, easy_to_use, cleaning, insurance, special_plate, distance, recovery_easy_to_use, recovery_cleaning, recovery_insurance, recovery_special_plate } = opt.formData;
  let partner_custom = {}

  distance = Math.round(distance);
  let interval, price;

  // get personalized prices
  partner_custom = JSON.parse(opt.partner_custom || '{}') || {};

  if (opt.partner === 4 && Object.keys(partner_custom).length) {
    for (let i = 1; i < 4; i++) {
      partner_custom[`${i}`] = partner_custom[`${i}`].map(e => e.coeff ? e.value * distance : e.value);
    }
  }

  let pricesByInterval = {
    // Véhicule léger
    1: {
      // Basique
      1: [80, 125, 150, 180, distance * 0.9, 359.1, distance * 0.8],
      4: partner_custom['1']
    },
    // Véhicule utilitaire - 12 m3
    2: {
      // Basique
      1: [80, 135, 165, 195, distance * 1, 399, distance * 0.9],
      4: partner_custom['2']
    },
    // Véhicule utilitaire + 12 m3
    3: {
      // Basique
      1: [80, 155, 180, 210, distance * 1.1, 438.9, distance * 1],
      4: partner_custom['3']
    },
  };

  if (distance > 0 && distance <= 49) {
    interval = 0;
  } else if (distance > 50 && distance <= 99) {
    interval = 1;
  } else if (distance > 100 && distance <= 149) {
    interval = 2;
  } else if (distance > 150 && distance <= 199) {
    interval = 3;
  } else if (distance > 200 && distance <= 399) {
    interval = 4;
  } else if (distance > 400 && distance <= 449) {
    interval = 5;
  } else if (distance >= 450) {
    interval = 6;
  }

  price = getValue(pricesByInterval, [vehicle_type, opt.partner, interval]);

  // calculate promo
  if (opt.partner === 1 && getValue(partner_custom, [vehicle_type, 'promo'])) {
    price = price - (price * (partner_custom[vehicle_type].promo * 0.01));
  }

  if (opt.recovery) {
    const recovery = partner_custom.recovery ? (partner_custom.recovery * 0.01) : 0.4;
    price = price + (price - (price * recovery));
  }

  if (opt.estimation) {
    if (special_plate) price += 20
  }

  if (price) {
    if (insurance) {
      price += 20;
      if (special_plate) price += 20;
    }
    if (recovery_insurance) {
      price += 20;
      if (recovery_special_plate) price += 20;
    }
    if (cleaning) price += 10;
    if (recovery_cleaning) price += 10;
    if (easy_to_use) price += 10;
    if (recovery_easy_to_use) price += 10;
    price = Number.isInteger(price) ? price : price.toFixed(2);
    return opt.removeSuffix ? price : `${price}€`;
  } else return '';
};

// 
// ─── CLICK OUTSIDE FUNCTION ───────────────────────────────────────
//
export const useOutsideClick = (callback) => {
  const ref = useRef();

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref]);

  return ref;
};

//
// ─── CHECK LENGTH OF OBJECT ───────────────────────────────────────
//
export const isEmptyObject = (originalObject) => {
  return Object.keys(originalObject).length === 0;
}

//
// ─── CHECK LENGTH OF AN ARRAY ───────────────────────────────────────
//
export const isEmptyArray = (originalArray) => {
  return originalArray.length === 0;
}

//
// ─── CHECK LENGTH ARRAY OF AN NESTED OBJECT ───────────────────────────────────────
//
export const isEmptyNestedObject = (originalObject) => {
  for (const key in originalObject) {
    if (Array.isArray(originalObject[key]) && !isEmptyArray(originalObject[key])) {
      return false;
    }
  }
  return true;
}

//
// ─── RETURN NOTIFS TO SHOW BY ROLE ───────────────────────────────────────
//
export const hasNotif = (role) => {
  let displayNotifs = [];
  const settings = window._DATA.settings;

  if (role !== 'garage' && settings.notification_driver.value) { displayNotifs.push(settings.notification_driver) }
  if (role !== 'driver' && settings.notification_garage.value) { displayNotifs.push(settings.notification_garage) }
  if (settings.notification.value) { displayNotifs.push(settings.notification) }

  return displayNotifs;
}