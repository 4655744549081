import styled from "styled-components";

const NoResults = () => {
  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    <S.Container>
      <img alt='Aucun résultat' src='/assets/img/no_result.svg' />
      <span>Aucun résultat</span>
    </S.Container>
  );
};

export default NoResults;

//
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {};

S.Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  & img {
    width: 400px;
  }
  & span {
    text-align: center;
    margin-top: 10px;
    font-size: 1.1em;
  }

  @media (max-width: 1300px) {
    & img {
      width: 300px;
    }
  }
`;
