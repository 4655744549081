import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Api from "../contexts/Api";
import Button from "../components/BaseComponents/Button";
import TitleSeparator from "../components/BaseComponents/TitleSeparator";
import { formatDate } from "../contexts/Utils";

const EventsList = (props) => {
  const [events, setEvents] = useState([]);
  const user_id = JSON.parse(localStorage.getItem('saint_bernard_auth')).user_id;

  useEffect(() => {
    const fetchData = async () => {
      let query = `status=${props.status}&user_id=${user_id}`;
      if (props.role === 'super-admin') query = `${query}&is_admin=true`

      const getEvent = await Api({ endpoint: `/events?${query}`, method: "GET" });

      setEvents(getEvent.data)
    }
    fetchData()
  }, [])

  const eventStatus = {
    1: 'Disponible',
    2: 'En cours',
    3: 'Annulé',
    4: 'Terminé',
    5: 'Supprimé',
  }

  return <div>
    {Boolean(events.length) && <S.EventsContainer>
      {events.map((event) => {

        let propoNumber = 0;
        const hasViews = event.events_views.length;

        event.events_duos.map((duos) => { propoNumber += duos.events_proposals.length })

        return <S.EventCard style={{ position: 'relative' }} to={`/evenements/${event.event_id}`}>
          <S.EventTitle>
            <h2>{event.label}</h2>
            <span>{`${event.address}, ${event.postal_code} ${event.city}`}</span>

            {event.status < 2 && props.role === 'super-admin' && (
              <Button style={{ position: 'absolute', top: 0, right: 0 }} size="icon" variant="ghost" href={`/evenements/${event.event_id}/editer`}>
                {/* prettier-ignore */}
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                  <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                  <path d="M16 5l3 3" />
                </svg>
              </Button>
            )}

          </S.EventTitle>
          <S.EventDetails>
            <S.DuoContainer>
              <TitleSeparator title="Prix" />
              <S.DuoContent>
                {Boolean(event.events_duos.length) && event.events_duos.map((duos, i) => {
                  if (duos.driver_id && event.status < 1) return null;
                  return <div>Binôme {i + 1} : <span>{duos.price}€</span></div>
                })}
              </S.DuoContent>
            </S.DuoContainer>
            <S.EventDate>
              <div>Date de l'événement :</div>
              <span>{formatDate({ time: event.time_event, display: 'date' })}</span>
            </S.EventDate>
          </S.EventDetails>
          <S.EventFooter>
            <div>{event.reference}</div>
            {Boolean(props.role === 'super-admin' && (propoNumber || hasViews)) && <div className="event-stats">
              {Boolean(propoNumber) && `${propoNumber} proposition${propoNumber > 1 ? 's' : ''} -`}
              {hasViews && ` ${hasViews} vue${hasViews > 1 ? 's' : ''}`}
            </div>}
            <div className="event-status">{eventStatus[event.status]}</div>
          </S.EventFooter>
        </S.EventCard>
      })}
    </S.EventsContainer>}
  </div>
};

export default EventsList;

const S = {};

S.Container = styled.div``;

S.EventsContainer = styled.div`
  display:grid;
  grid-template-columns:repeat(2, minmax(300px, 1fr));
  gap: 30px; 
  width: 100%;
  align-items: stretch;
  & > div{
  }

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
`;

S.EventCard = styled(Link)`
  text-decoration: none;
  background:white;
  box-shadow: 0 20px 27px 0 rgb(0 ,0, 0, 5%);
  text-decoration: none;
  color:black;
  border-radius: 5px;
  overflow: hidden;
`

S.EventTitle = styled.div`
  padding:10px 0 0 20px;
  h2{
    font-size: 1.5rem;
    font-weight: bold;
    color:var(--primary-color);
    margin:0;
  }
  span{
    color:var(--secondary-color);
  }
`

S.EventDetails = styled.div`
  display: flex;
  margin-top: 20px;
`

S.DuoContainer = styled.div`
  padding:0 20px;
  width: 100%;
`

S.DuoContent = styled.div`
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  gap:20px;
  margin-bottom: 10px;
  div{
    span{
      font-weight: bold;
      color:var(--primary-color);
      font-size: 18px;
    }
  }
`

S.EventDate = styled.div`
  width:110px;
  padding:20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:5px;
  color:white;
  background:var(--secondary-color);
  border-top-left-radius: 10px;
  div{
    font-size: 14px;
  }
  span{
    font-weight: bold;
    font-size: 20px;
  }
`

S.EventFooter = styled.div`
  display: flex;
  /* justify-content: space-between; */
  
  margin-top: 1px;
  font-size: 14px;
  div{
    flex:1;
  }
  div:nth-child(1){
    padding:10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background:#2D2D2D;
    text-align: center;
    color:white;
  }
  .event-stats{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:10px 20px;
  }
  .event-status{
    padding:10px 0;
    background:var(--secondary-color);
    text-align: center;
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1300px) {
    div {
      flex: 1
    }
  }

  @media (max-width: 640px) {
    & {
      flex-direction: column !important;
    }
  }
`