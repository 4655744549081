import styled from "styled-components";
import { useEffect, useState } from "react";
import SearchBarHome from "../../components/Header/SearchBar";
import { Avatar, Divider } from "@mui/material";
import { Link, NavLink, useLocation } from "react-router-dom";
import { hasNotif, useOutsideClick } from "../../contexts/Utils";
import Button from "../../components/BaseComponents/Button";
import TitleSeparator from "../../components/BaseComponents/TitleSeparator";

const Header = (props) => {
  const userRole = props.user.role;
  const userDisabled = props.user.disabled;
  const userStatus = props.user.status;
  const [profileOpen, setProfileOpen] = useState(false);
  const [openBurger, setOpenBurger] = useState(false);
  const accountRef = useOutsideClick(() => setProfileOpen(false));
  const notifs = hasNotif(userRole);
  const hasNotifs = Boolean(notifs.length);

  const handleLogout = () => {
    localStorage.removeItem('saint_bernard_auth');
    window.location.href = '/';
  }

  // Close burger on location change
  const location = useLocation();
  useEffect(() => {
    setOpenBurger(false);
  }, [location]);

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    <S.Container $hasNotifs={hasNotifs}>
      {userRole === 'super-admin' && <SearchBarHome />}
      {window.innerWidth > 1300 ? <S.Actions>
        {userRole !== 'driver' && (
          <S.NewMissionButton href="/nouvelle-mission">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12 8V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M8 12H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            Nouvelle mission
          </S.NewMissionButton>
        )}

        {userRole === 'super-admin' && (
          <>
            <S.NewMissionButton href="/nouvel-evenement">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12 8V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8 12H16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Nouvel événement
            </S.NewMissionButton>
            <S.ActionNew href="/ajouter-un-compte" variant="outline" buttonSize="icon" className="shadow-md">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_21_169)">
                  <path d="M10.5 16.8234V20.8781C6.73125 20.2453 3.75469 17.2687 3.12656 13.5H7.91719L10.5 16.8234ZM13.5 16.8234L16.0828 13.5H20.8734C20.2453 17.2687 17.2687 20.2453 13.5 20.8734V16.8234ZM16.5 10.5L16.1625 9.82969C15.9094 9.32344 15.3891 9 14.8219 9H9.17813C8.61094 9 8.09062 9.31875 7.8375 9.82969L7.5 10.5H3.12656C3.83906 6.24375 7.54219 3 12 3C16.4578 3 20.1609 6.24375 20.8734 10.5H16.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z" fill="currentColor" />
                </g>
                <defs>
                  <clipPath id="clip0_21_169">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span className="plus">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" >
                  <rect x="1" y="1" width="15" height="15" rx="7.5" fill="#14483C" />
                  <rect x="1" y="1" width="15" height="15" rx="7.5" stroke="white" strokeWidth="2" />
                  <path d="M5 8.5H12" stroke="white" strokeWidth="1.5" />
                  <path d="M8.5 12L8.5 5" stroke="white" strokeWidth="1.5" />
                </svg>
              </span>
            </S.ActionNew>
          </>
        )}
        {['super-admin', 'garage'].includes(userRole) && <Button variant="outline" buttonSize="icon" onClick={() => { document.querySelector('.estimation_modal').classList.add('estimation_modal--open') }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="26" viewBox="0 0 16 26" fill="none">
            <path d="M2.405 12.1875C2.4 12.3246 2.4 12.4566 2.4 12.5938V13.4062C2.4 13.5434 2.4 13.6754 2.405 13.8125H1.6C0.715 13.8125 0 14.5387 0 15.4375C0 16.3363 0.715 17.0625 1.6 17.0625H3.015C4.495 21.323 8.5 24.375 13.2 24.375H14.4C15.285 24.375 16 23.6488 16 22.75C16 21.8512 15.285 21.125 14.4 21.125H13.2C10.305 21.125 7.79 19.4797 6.505 17.0625H12.8C13.685 17.0625 14.4 16.3363 14.4 15.4375C14.4 14.5387 13.685 13.8125 12.8 13.8125H5.61C5.605 13.6805 5.6 13.5434 5.6 13.4062V12.5938C5.6 12.4566 5.605 12.3195 5.61 12.1875H12.8C13.685 12.1875 14.4 11.4613 14.4 10.5625C14.4 9.66367 13.685 8.9375 12.8 8.9375H6.505C7.79 6.52031 10.305 4.875 13.2 4.875H14.4C15.285 4.875 16 4.14883 16 3.25C16 2.35117 15.285 1.625 14.4 1.625H13.2C8.5 1.625 4.495 4.67695 3.015 8.9375H1.6C0.715 8.9375 0 9.66367 0 10.5625C0 11.4613 0.715 12.1875 1.6 12.1875H2.405Z" fill="currentColor" />
          </svg>
        </Button>}
        <S.Avatar ref={accountRef} onClick={() => setProfileOpen(!profileOpen)}>
          <span className="text">
            {userRole === 'driver' || userRole === "super-admin"
              ? `${props.user?.firstname.charAt(0)}${props.user?.name.charAt(0)}`
              : props.user?.company.slice(0, 2)}
          </span>
          <span className="more">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" fill="none">
              <g filter="url(#filter0_dd_20_148)">
                <rect x="0" y="0" width="24" height="24" rx="12" fill="white" />
                <path d="M16.8995 8.9497L11.9497 13.8995L7 8.9497" stroke="#323232" strokeWidth="1.5" id="avatar-more-arrow" />
              </g>
              <defs>
                <filter id="filter0_dd_20_148" x="0" y="0" width="110" height="110" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="2" />
                  <feGaussianBlur stdDeviation="22" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20_148" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="2" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                  <feBlend mode="normal" in2="effect1_dropShadow_20_148" result="effect2_dropShadow_20_148" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_20_148" result="shape" />
                </filter>
              </defs>
            </svg>
          </span>
          {profileOpen && (
            <S.Dropdown>
              <NavLink to="/mon-compte">
                <Avatar /> <span>Mon compte</span>
              </NavLink>
              <Divider />
              {userRole === 'super-admin' && <NavLink to="/parametres">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style={{ transform: 'translateX(-2px)' }} viewBox="0 0 512 512"><path d="M482.7 299.3l-32.6-18.8a195.2 195.2 0 0 0 0-48.9l32.6-18.8c9.6-5.5 14.2-16.9 11-27.5-11.2-37.7-31.2-71.7-57.5-99.6-7.6-8.1-19.8-9.8-29.4-4.3l-32.6 18.8a194.1 194.1 0 0 0 -42.3-24.5V38c0-11.1-7.7-20.8-18.5-23.4-37.6-8.9-77.1-8.9-114.8 0-10.8 2.6-18.5 12.2-18.5 23.4v37.6a194.1 194.1 0 0 0 -42.3 24.5L105.2 81.3c-9.6-5.6-21.8-3.8-29.4 4.3-26.4 27.9-46.3 61.8-57.5 99.6-3.1 10.6 1.5 22 11 27.5l32.6 18.8a195.2 195.2 0 0 0 0 48.9l-32.6 18.8c-9.6 5.5-14.2 16.9-11 27.5 11.2 37.7 31.2 71.7 57.5 99.6 7.6 8.1 19.8 9.8 29.4 4.3l32.6-18.8a194.1 194.1 0 0 0 42.3 24.5v37.6c0 11.1 7.7 20.8 18.5 23.4 37.6 8.9 77.1 8.9 114.8 0 10.8-2.6 18.5-12.2 18.5-23.4v-37.6a194.1 194.1 0 0 0 42.3-24.5l32.6 18.8c9.6 5.6 21.8 3.8 29.4-4.3 26.4-27.9 46.3-61.8 57.5-99.6 3.1-10.6-1.5-22-11-27.5zm-65.5 100.5l-46.3-26.7c-27 23.1-36.6 28.9-71 41.1v53.5a217.1 217.1 0 0 1 -87.7 0v-53.5c-33.6-11.9-43.4-17.4-71-41.1l-46.3 26.7c-19.7-22.1-34.7-48-43.9-76l46.3-26.7c-6.5-35.4-6.5-46.6 0-82.1l-46.3-26.7c9.2-28 24.2-53.9 43.9-76l46.3 26.8c27.4-23.4 37.1-29.1 71-41.1V44.5a216.8 216.8 0 0 1 87.7 0v53.5c34 12 43.7 17.6 71 41.1l46.3-26.8c19.7 22.1 34.7 48 43.9 76l-46.3 26.7c6.5 35.4 6.5 46.6 0 82.1l46.3 26.7c-9.2 28-24.2 53.9-43.9 76zM256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm0 160c-35.3 0-64-28.7-64-64s28.7-64 64-64 64 28.7 64 64-28.7 64-64 64z" /></svg>                <span>Paramètres</span>
              </NavLink>}
              <div className="logout" onClick={handleLogout}>
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-logout" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" /><path d="M9 12h12l-3 -3" /><path d="M18 15l3 -3" /></svg>
                <span>Déconnexion</span>
              </div>
            </S.Dropdown>)}
        </S.Avatar>
      </S.Actions > : <S.Burger>
        <S.Open onClick={() => setOpenBurger(!openBurger)}>{!openBurger ? <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 6l16 0" /><path d="M4 12l16 0" /><path d="M4 18l16 0" /></svg> : <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>}</S.Open>
        {openBurger && <S.DropdownBurger $height={window.innerHeight} $hasNotifs={hasNotifs}>
          <Link to="/">
            <img className="logo" src="/assets/img/logo.png" alt="Logo saint bernard" />
          </Link>
          <S.Navigation>
            <TitleSeparator title='Mon compte' />
            <ul>
              <li><NavLink to="/mon-compte"> Mon compte</NavLink></li>
              {userRole === 'super-admin' && <li><NavLink to="/parametres"> Paramètres</NavLink></li>}
              <li><div className="logout" onClick={handleLogout}> Déconnexion</div></li>
            </ul>

            {['garage', 'super-admin'].includes(userRole) && <>
              <TitleSeparator title='Actions' />
              <ul>
                {userRole !== 'driver' && <li><NavLink to="/nouvelle-mission">Nouvelle mission</NavLink></li>}
                {userRole === 'super-admin' && <>
                  <li><NavLink to="/nouvel-evenement">Nouvel événement</NavLink></li>
                  <li><NavLink to='/ajouter-un-compte'>Nouveau compte</NavLink></li>
                </>}
              </ul></>}

            <TitleSeparator title='Missions' />
            <ul>
              <li>
                {!userDisabled && Boolean(userStatus) && <NavLink to="/" end>
                  {['garage'].includes(userRole) ? 'Mes convoyages' : 'Toutes les missions'}
                </NavLink>}
              </li>
              {['driver'].includes(userRole) && !userDisabled && Boolean(userStatus) && (
                <li><NavLink to="/mes-missions">Mes missions</NavLink></li>
              )}
              {['super-admin', 'garage'].includes(userRole) && !userDisabled && Boolean(userStatus) && (
                <li><NavLink to="/mission-en-attente">Convoyage en attente</NavLink></li>
              )}
              {Boolean(userStatus) && <li><NavLink to="/historique">Historique</NavLink></li>}
            </ul>
            {['super-admin', 'driver'].includes(userRole) && <>
              <TitleSeparator title='Événements' />
              <ul>
                <li><NavLink to="/evenements">Tous les événements</NavLink></li>
                <li><NavLink to="/evenements-en-cours">En cours</NavLink></li>
                <li><NavLink to="/historique-evenements">Historique</NavLink></li>
              </ul>
            </>}
            {['super-admin'].includes(userRole) && <>
              <TitleSeparator title='Utilisateurs' />
              <ul>
                <li><NavLink to="/utilisateurs">Utilisateurs</NavLink></li>
                <li><NavLink to="/penalites">Pénalités</NavLink></li>
              </ul>
            </>}
          </S.Navigation>
        </S.DropdownBurger>}
      </S.Burger>}
    </S.Container >
  );
};

export default Header;

const S = {};

S.Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
  position: relative;
  background:#EFEFEF;
  /* padding:20px 0; */
  padding:20px 0;
  margin-top: ${({ $hasNotifs }) => $hasNotifs ? '0' : '-20px'};
`;

S.Actions = styled.div`
  display: none;
  gap: 1rem;
  margin-left: auto;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
  }
`;

S.ActionNew = styled(Button)`
  position: relative;

  .plus {
    position: absolute;
    transform: translate(60%, 60%);
  }
`;

S.Avatar = styled.div`
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  aspect-ratio: 1 / 1;
  background: white;
  border: 2px solid var(--primary-color);

  &.active {
    span.more #avatar-more-arrow {
      transform: rotateX(180deg);
    }
  }

  span.more {
    position: absolute;
    bottom: -0.5rem;
    right: -0.5rem;

    #avatar-more-arrow {
      transition: all 0.2s ease-in-out;
      transform-origin: center;
    }
  }
`;

S.NewMissionButton = styled(Button)`
  gap: 10px;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  background: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

S.Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background: white;
  padding: 20px;
  border-radius: 5px;
  top: 4rem;
  right: 0;
  z-index: 99;
  width: 200px;
  gap: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);

  & a, .logout {
    text-decoration: none;
    color: black;
    display: flex;
    gap: 10px;
    align-items: center;
  }
`

S.Burger = styled.div`
  margin-left: auto;
`;

S.Open = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: var(--primary-color);
  cursor: pointer;

  & svg {
    color: var(--white)
  }
`;

S.DropdownBurger = styled.div`
  position: absolute;
  top: ${({ $hasNotifs }) => $hasNotifs ? 8.5 : 5}rem;
  right: 0;
  background: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 99;
  width: 100%;
  box-sizing: border-box;
  gap: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 99;
  max-height: ${({ $height }) => $height - 150}px;
  overflow-y: scroll;

  .logo{
    display: block;
    margin:20px auto 0;
    width:150px;
  }
`;

S.Navigation = styled.nav`
  ul{
    padding:0 0 0 15px;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap:15px;
    margin-bottom: 40px;
    li{
      a{
        color:#2D2D2D;
        text-decoration: none;
      }
    }
  }
`