import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Api from "../../contexts/Api";
import { formatDate } from "../../contexts/Utils";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const MissionDetailViews = (props) => {
  const classes = useStyle();
  const [users, setUsers] = useState([])
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const getUsersViews = await Api({ endpoint: `/missions/views?mission_id=${props.mission_id}`, method: 'GET', });
      setUsers(getUsersViews.data);
    }

    fetchData();
  }, [props.mission_id])

  const goToDriverPage = (id) => {
    return navigate(`/utilisateurs/${id}`);
  }

  return Boolean(users.length) && <S.Container>
    <h2>Chauffeurs ayant vu l'annonce</h2>
    <S.GridTable>
      <S.TableContainer className="shadow-md rounded-md">
        <S.Table>
          <thead>
            <tr>
              <th>Prénom</th>
              <th>Nom</th>
              <th>Email</th>
              <th>Téléphone</th>
              <th>Jour - heure</th>
            </tr>
          </thead>

          <tbody>
            {users.map((user) => {
              return (
                <S.Tr key={user.user_id} onClick={() => goToDriverPage(user.user_id)}>
                  <td>{user.firstname}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{formatDate({ time: user.time_create })}</td>
                </S.Tr>
              );
            })}
          </tbody>
        </S.Table>
      </S.TableContainer>
    </S.GridTable>

  </S.Container>
}

export default MissionDetailViews

//
// ─── INITIALISATION DES STYLES ───────────────────────────────────────
//
const useStyle = createUseStyles({
  container: {
    background: '#FFF',
    padding: '20px 0 0 20px',
    boxShadow: ' 0 20px 27px 0 rgb(0 ,0, 0, 5%)',
    marginTop: 20
  },
  users: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    maxHeight: 205,
    overflowY: 'auto',
    padding: '0 20px 20px 0'
  },
  userLine: {
    display: 'flex',
    gap: 40
  }
});

const S = {};

S.Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 20px;
`;

S.GridTable = styled.div`
  max-height: 300px;
  overflow-y: scroll;
`;

S.Tr = styled.tr`
  cursor: pointer;
`;

S.TableContainer = styled.div`
  max-width: 100%;
  width: 100%;

  @media (max-width: 1300px) {
    overflow-x: scroll;
  }
`;

S.Table = styled.table`
  border-radius: 0.5rem;
  border: 0;
  border-collapse: collapse;
  width: 100%;

  thead {
    background: var(--card-light);
    color: var(--white);
    text-align: left;
  }

  thead > tr > th:first-child {
    border-top-left-radius: 0.5rem;
  }

  thead > tr > th:last-child {
    border-top-right-radius: 0.5rem;
  }

  tbody {
    background: var(--white);
    color: var(--text-color);
  }

  tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 0.5rem;
  }

  tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 0.5rem;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid var(--card-dark);
  }

  th,
  td {
    padding: 1rem;

    &:first-child {
      padding-left: 2rem;
    }

    &:last-child {
      padding-right: 2rem;
    }
  }

  th {
    padding: 1.25rem 1rem;
  }
`;