// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../slice/auth';
import Api from '../contexts/Api';
import { prepareFormData } from '../contexts/Utils';
import Formfields from '../components/Formfields';
import { useNotification } from '../components/UseNotification';
import styled from 'styled-components';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Login = () => {

  const dispatch = useDispatch()
  let navigate = useNavigate();
  const { notification } = useNotification()

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: "email", component: "text", name: "email", label: "Email" },
    { type: "password", component: "text", name: "password", label: "Mot de passe" },
  ]

  // 
  // ─── HANDLE USER LOGIN ───────────────────────────────────────
  //
  const handleChange = async () => {
    const data = prepareFormData({ formId: 'loginForm' });

    const params = {
      data,
      endpoint: '/auth/login'
    }

    const logUser = await Api(params)

    // 
    // ─── ON SUCCESS WE USE REDUX TO LOG USER ───────────────────────────────────────
    //
    if (logUser.success && logUser.access_token) {
      dispatch(login({ access_token: logUser.access_token, user_id: logUser.user_id }))
      return navigate("/");
    } else {
      notification({ variant: 'error', message: logUser.message })
    }
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.EstimationButton variant="outline" buttonSize="icon" onClick={() => { document.querySelector('.estimation_modal').classList.add('estimation_modal--open') }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="26" viewBox="0 0 16 26" fill="none">
        <path d="M2.405 12.1875C2.4 12.3246 2.4 12.4566 2.4 12.5938V13.4062C2.4 13.5434 2.4 13.6754 2.405 13.8125H1.6C0.715 13.8125 0 14.5387 0 15.4375C0 16.3363 0.715 17.0625 1.6 17.0625H3.015C4.495 21.323 8.5 24.375 13.2 24.375H14.4C15.285 24.375 16 23.6488 16 22.75C16 21.8512 15.285 21.125 14.4 21.125H13.2C10.305 21.125 7.79 19.4797 6.505 17.0625H12.8C13.685 17.0625 14.4 16.3363 14.4 15.4375C14.4 14.5387 13.685 13.8125 12.8 13.8125H5.61C5.605 13.6805 5.6 13.5434 5.6 13.4062V12.5938C5.6 12.4566 5.605 12.3195 5.61 12.1875H12.8C13.685 12.1875 14.4 11.4613 14.4 10.5625C14.4 9.66367 13.685 8.9375 12.8 8.9375H6.505C7.79 6.52031 10.305 4.875 13.2 4.875H14.4C15.285 4.875 16 4.14883 16 3.25C16 2.35117 15.285 1.625 14.4 1.625H13.2C8.5 1.625 4.495 4.67695 3.015 8.9375H1.6C0.715 8.9375 0 9.66367 0 10.5625C0 11.4613 0.715 12.1875 1.6 12.1875H2.405Z" fill="currentColor" />
      </svg>
      <span>Estimation</span>
    </S.EstimationButton>
    <S.FirstColumn>
      <S.Logo src='/assets/img/logo-main.png' alt='Logo Saint-Bernard' />
      <S.TitleLeft>Votre plateforme de convoyage</S.TitleLeft>
    </S.FirstColumn>
    <S.SecondColumn>
      <S.Background src='/assets/img/background.png' alt='Background' />
      <S.Form name='loginForm'>
        <S.Title>Connexion</S.Title>
        {formFields.map((el) => (<Formfields key={el.name} field={el} />))}
        <Button variant="contained" onClick={handleChange} autoFocus>Connexion</Button>
        <S.Link to="/mot-de-passe-oublie">J'ai oublié mon mot de passe</S.Link>
        <S.Link to="/inscription">Je n'ai pas de compte</S.Link>
      </S.Form>
    </S.SecondColumn>
  </S.Container>
};

export default Login;

let S = {};

S.Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;

  @media screen and (max-width:1300px) {
    grid-template-columns: 1fr;
  }
`;

S.FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  background: #15483c;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media screen and (max-width:1300px) {
    display: none;
  }
`

S.SecondColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

S.Background = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  inset: 0;
  z-index: -1;
`

S.Logo = styled.img`
  width: 50px;
  height: 50px;

  @media screen and (max-width:450px) {
    width: 150px;
  }
`;

S.Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 24rem;
  padding: 2rem;
  background: white;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  width: 50%;

  @media (min-width: 1024px) {
    padding: 2rem;
    width: 30%;
  }

  & .MuiButtonBase-root {
    width: 100%;
  }
`;

S.TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

S.Title = styled.h3`
  margin-top: 0;
  color: var(--primary-color);
  @media screen and (max-width:450px) {
    font-size: 20px;
  }
`;

S.TitleLeft = styled.h1`
  color: #FFF;
`

S.Link = styled(Link)`
  color: black;
  text-decoration: none;
  font-size:12px;
  cursor: pointer;

  &:hover {
    color: var(--primary-color);
  }
`

S.EstimationButton = styled(Button)`
  position: fixed !important;
  top:20px;
  left:20px;
  background:white !important;
  border:2px solid var(--secondary-color) !important;
  padding:10px !important;
  span{
    margin-left: 15px;
  }
`