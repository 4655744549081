import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Formfields from '../Formfields';
import { prepareFormData, getValue } from '../../contexts/Utils';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Steps = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const location = useLocation();

  const handleNext = () => {
    const data = prepareFormData({ formId: 'myForm' });
    if (data.errors) {
      setErrors(data.errors);
    } else {
      if (Object.keys(errors).length) {
        setErrors({});
      }
      setForm({ ...form, ...data.fields });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleFinish = () => {
    const data = prepareFormData({ formId: 'myForm' });

    if (data.errors) {
      setErrors(data.errors);
    } else {
      setForm({ ...form, ...data.fields });
      props.handleChange({ ...form, ...data.fields });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      maxWidth: 800,
      margin: 'auto',
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    titleContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      width: '100%',
      marginTop: 0,
      color: 'var(--primary-color)',
      '@media screen and (max-width:450px)': {
        fontSize: 20,
      },
    },
    logo: {
      '@media screen and (max-width:450px)': {
        width: 150,
      },
    },
    steps: {
      width: '100%',
      maxWidth: 800,

      '& .MuiStepLabel-label': {
        color: 'white!important',
      }
    },
  });
  const classes = useStyle();

  const adminRegister = location.pathname === '/ajouter-un-compte';

  return (
    <S.Container $isAdmin={adminRegister}>
      {!adminRegister && <S.FirstColumn>
        <S.Logo src='/assets/img/logo-main.png' alt='Logo Saint-Bernard' />
        <S.TitleLeft>Inscription</S.TitleLeft>
        {activeStep !== props.stepsLabel.length && props.stepsLabel.length > 1 && (
          <Stepper activeStep={activeStep} className={classes.steps}>
            {props.stepsLabel.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}
      </S.FirstColumn>}
      <S.SecondColumn>
        {!adminRegister && <S.Background src='/assets/img/background.png' alt='Background' />}
        {activeStep !== props.stepsLabel.length && (
          <>
            <S.Form id='myForm'>
              <S.FormFieldsContainer>
                {props.formFields[activeStep].fields.map((field) => {
                  const defaults = form[field.name] || field.default;
                  const checkCond = field.cond || !field.hasOwnProperty('cond');

                  return (
                    checkCond && (
                      <Formfields
                        key={field.name}
                        field={{
                          ...field,
                          ...{ default: defaults, error: getValue(errors, [field.name, 'message']) },
                        }}
                      />
                    )
                  );
                })}
              </S.FormFieldsContainer>

              <S.ButtonContainer>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Retour
                </Button>
                {props.userType !== 0 && (
                  <Button
                    onClick={activeStep === props.stepsLabel.length - 1 ? handleFinish : handleNext}
                  >
                    {activeStep === props.stepsLabel.length - 1 ? 'Terminer' : 'Suivant'}
                  </Button>
                )}
              </S.ButtonContainer>
            </S.Form>
          </>
        )}
      </S.SecondColumn>
    </S.Container>
  );
};

export default Steps;

let S = {};

S.Container = styled.div`
  display: grid;
  grid-template-columns: ${({ $isAdmin }) => $isAdmin ? '1fr' : '1fr 1fr'};
  min-height: 100vh;

  @media screen and (max-width:1300px) {
    grid-template-columns: 1fr;
  }
`;

S.FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  background: #15483c;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 20px;
  @media screen and (max-width:1300px) {
    display: none;
  }
`

S.SecondColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px;

  @media (max-width: 1300px) {
    padding: 0;
  }
`

S.Background = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  inset: 0;
  z-index: -1;
`

S.Logo = styled.img`
  width: 50px;
  height: 50px;

  @media screen and (max-width:450px) {
    width: 150px;
  }
`;

S.Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 10px;
  height: auto;
  width: auto;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1024px) {
    padding: 2rem;
  }
`;

S.FormFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: auto 0;
`

S.TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

S.Title = styled.h3`
  margin-top: 0;
  color: var(--primary-color);
  @media screen and (max-width:450px) {
    font-size: 20px;
  }
`;

S.TitleLeft = styled.h1`
  color: #FFF;
`

S.Link = styled(Link)`
  color: black;
  text-decoration: none;
  font-size:15px;
  cursor: pointer;

  &:hover {
    color: var(--primary-color);
  }
`

S.ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`