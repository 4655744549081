import styled from 'styled-components';

const Modal = (props) => {
  const { show, handleClose, children, title } = props;
  return show ? <S.ModalWrapper>
    <S.Modal>
      <h2>{title}</h2>
      <div className="modal-main">
        <div className="modal-content">{children}</div>
        <S.Close onClick={(handleClose)} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M326.6 166.6L349.3 144 304 98.7l-22.6 22.6L192 210.7l-89.4-89.4L80 98.7 34.7 144l22.6 22.6L146.7 256 57.4 345.4 34.7 368 80 413.3l22.6-22.6L192 301.3l89.4 89.4L304 413.3 349.3 368l-22.6-22.6L237.3 256l89.4-89.4z" /></S.Close>
      </div>
    </S.Modal>
  </S.ModalWrapper> : <></>
};

export default Modal;

const S = {};

S.ModalWrapper = styled.div`
  position:fixed;
  inset:0;
  background:rgba(0,0,0,0.6);
  display:flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

S.Modal = styled.div`
  position:relative;
  width:100%;
  max-width: 800px;
  background:#FFF;
  box-shadow:${({ theme }) => theme.box_shadow};
  border-radius:8px;
  padding:20px;
`

S.Close = styled.svg`
  position: absolute;
  top:10px;
  right:10px;
  width:25px;
  height:25px;
  cursor: pointer;
`