// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import Api from '../contexts/Api';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { getValue } from '../contexts/Utils';
import styled from "styled-components";
import { Status } from '../components/StyledComponents';
import Button from '../components/BaseComponents/Button';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Users = () => {
  const storedData = JSON.parse(localStorage.getItem('saint_bernard_auth'));
  const [users, setUsers] = useState({ list: [], filtred: [] })

  //
  // ─── SEARCH REQUEST TO GET ALL MISSIONS FILTRED BY PARAMS AND FILTERS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchUsers = async () => {
      const getUsers = await Api({
        endpoint: `/users?user_id=${storedData.user_id}&search_all=1&remove_admin=true`,
        method: 'GET',
      });
      if (getUsers.data) {
        setUsers({ list: getUsers.data, filtred: [] })
      }
    };
    if (!users.list.length) {
      fetchUsers();
    }
  }, [storedData.user_id, users]);

  const handleChange = async (user_id) => {
    const params = {
      endpoint: `/users`,
      method: 'PUT',
      data: {
        user_id: storedData.user_id,
        company_id: user_id,
        validate_user: true,
        fields: {
          status: 1,
        }
      },
    };

    await Api(params);

    setUsers(users.filter((element) => element.user_id !== user_id))
  }

  const handleDeleteUser = async (user_id) => {
    const params = {
      endpoint: `/users`,
      method: 'DELETE',
      data: {
        user_id: storedData.user_id,
        company_id: user_id,
      },
    };

    await Api(params);

    setUsers(users.filter((element) => element.user_id !== user_id))
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      maxWidth: 1200,
      margin: 'auto',
      padding: 20
    },
    container: {
      boxShadow: " 0 20px 27px 0 rgb(0 ,0, 0, 5%)",
      padding: 20,
      background: '#FFF',
      marginBottom: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    type: {
      fontSize: 12,
      padding: '4px 8px'
    },
    content: {
      display: 'flex',
      gap: 20,
    },
    link: {
      textDecoration: 'none',
      color: 'var(--primary-color)',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    filters: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: 10,
      color: 'var(--subtitle-color)'
    },
    filtersBtn: {
      display: 'flex',
      gap: 40,
      cursor: 'pointer',
      '& > div:hover ': {
        color: 'var(--primary-color)',
      }
    },
    filtersSearch: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },
    filtersSearchInput: {
      background: 'none',
      border: '1px solid #e1e1e1',
      padding: 5,
      width: 240,
      outline: 'none'
    }
  })
  const classes = useStyle();

  let usersDisplay = users.list;

  if (users.filtred.length) {
    usersDisplay = users.filtred
  }

  const TypeColor = {
    1: { background: '#afd0ff', color: 'black' },
    2: { background: '#fbc857', color: 'black' },
  }


  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container >
    <h1>Utilisateurs</h1>
    <div className={classes.filters}>
      <S.FilterButtons>
        <div onClick={() => setUsers({ list: users.list, filtred: [] })}>Tous les utilisateurs</div>
        <div onClick={() => setUsers({ list: users.list, filtred: users.list.filter(element => element.status === 0) })}>En attente</div>
        <div onClick={() => setUsers({ list: users.list, filtred: users.list.filter(element => element.user_type === 1) })}>Chauffeurs</div>
        <div onClick={() => setUsers({ list: users.list, filtred: users.list.filter(element => element.user_type === 2) })}>Garages</div>
      </S.FilterButtons>
    </div>
    <S.TableContainer className="shadow-md rounded-md">
      <S.Table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Email</th>
            <th>Téléphone</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {usersDisplay.map((user) => {
            const firstname = user.role === 'driver' ? user.firstname : "";
            const name = user.role === 'driver' ? user.name.toUpperCase() : user.company;
            return (
              <tr key={user.user_id}>
                <td><Status $status={user.user_type} $colors={TypeColor}>{getValue(window, ['_DATA', 'user_type', user.user_type, 'label'])}</Status></td>
                <td>{firstname}</td>
                <td>{name}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <S.Actions>
                  <Link to={`/utilisateurs/${user.user_id}`} style={{ marginLeft: 'auto' }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg></Link>
                  {user.status === 0 && <>
                    <Button onClick={() => handleChange(user.user_id)} variant="primary" className="shadow-md">Valider</Button>
                    <Button onClick={() => handleDeleteUser(user.user_id)} variant="error" className="shadow-md">Refuser</Button>
                  </>}
                </S.Actions>
              </tr>
            );
          })}
        </tbody>
      </S.Table>
    </S.TableContainer>
  </S.Container >
};

export default Users;

const S = {};
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

S.FilterButtons = styled.div`
  display: flex;
  gap: 40px;
  cursor: pointer;
  & > div:hover {
    color: var(--primary-color);
  }

  @media (max-width: 1300px) {
    flex-wrap: wrap;
  }
`;

S.TableContainer = styled.div`
  max-width: 100%;
  width: 100%;

  @media (max-width: 1300px) {
    overflow-x: scroll;
  }
`;

S.Table = styled.table`
  border-radius: 0.5rem;
  border: 0;
  border-collapse: collapse;
  width: 100%;

  thead {
    background: var(--card-light);
    color: var(--white);
    text-align: left;
  }

  thead > tr > th:first-child {
    border-top-left-radius: 0.5rem;
  }

  thead > tr > th:last-child {
    border-top-right-radius: 0.5rem;
  }

  tbody {
    background: var(--white);
    color: var(--text-color);
  }

  tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 0.5rem;
  }

  tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 0.5rem;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid var(--card-dark);
  }

  th,
  td {
    padding: 1rem;
  }

  th {
    padding: 1.25rem 1rem;
  }
`;

S.Actions = styled.td`
  display: flex;
  gap: 10px;
  align-items: center;

  & svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: var(--primary-color);
  }

  & .shadow-md {
    height: 2rem
  }
`;