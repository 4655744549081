//
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSearchParams, useLocation } from 'react-router-dom';
import Api from '../contexts/Api';
import Mission from '../components/Mission';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import NoResults from '../components/BaseComponents/NoResults';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const MissionsList = (props) => {
  const [offset, setOffset] = useState(0)
  const [missions, setMissions] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [sort, setSort] = useState();
  const [status, setStatus] = useState();
  const [searchParams] = useSearchParams();
  let search = '';
  const user_id = JSON.parse(localStorage.getItem('saint_bernard_auth')).user_id;
  const isUserMissions = props.user_missions;
  const isValidateMissions = props.to_validate;
  const isHistoryMissions = props.history;
  const location = useLocation();
  const { ref, inView } = useInView();

  //
  // ─── FORMAT SEARCH PARAMS ───────────────────────────────────────
  //
  for (const entry of searchParams.entries()) {
    search += '&' + entry.join('=');
  }

  const { pathname } = useLocation();
  useEffect(() => {
    setMissions(null)
  }, [pathname])

  //
  // ─── SEARCH REQUEST TO GET ALL MISSIONS FILTRED BY PARAMS AND FILTERS ───────────────────────────────────────
  //
  // useEffect(() => {
  const fetchMissions = async ({ infinite }) => {
    const getMissions = await Api({
      endpoint: `/missions?user_id=${user_id}${search}${sort ? '&sort=' + sort : ''}${isUserMissions ? `&driver_id=${user_id}` : ''}${isValidateMissions ? `&company_id=${props.role === 'super-admin' ? 'all' : user_id}&status=${status || '2,3,4'}` : ''}${isHistoryMissions ? `&status=5,6,7&${props.role === 'driver' ? 'driver_id' : 'company_id'}=${props.role === 'super-admin' ? 'all' : user_id}&history=true` : ''}${Boolean(props.search) && ['garage'].includes(props.role) ? `&company_id=${user_id}` : ''}&offset=${!infinite ? 0 : offset + 40}${props.role === 'super-admin' ? '&admin=1' : ''}`,
      method: 'GET',
    });
    setLoaded(true);
    if (getMissions.success) {
      if (infinite) {
        setMissions([...missions, ...getMissions.data]);
        setOffset(offset + 40)
      } else {
        setMissions(getMissions.data)
      }
    }
  };
  // }, [search, sort, user_id, isUserMissions, isValidateMissions, isHistoryMissions, props.role, status, props.search]);

  useEffect(() => {
    fetchMissions({ infinite: false });
  }, [location.pathname])

  useEffect(() => {
    if (inView && (missions?.length || 0) >= 20) {
      fetchMissions({ infinite: true });
    }
  }, [inView])

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      margin: '0 auto',
      padding: 20,
    },
    search: {
      marginBottom: 60,
    },
    container: {
      display: 'flex',
      gap: 20,
      '@media screen and (max-width:780px)': {
        flexDirection: 'column',
      },
    },
    detail: {
      width: '100%',
      minHeight: 'calc(100vh - 74px)',
    },
    missions: {
      width: '100%',
    },
    mission: {
      marginBottom: 20,
    },
    filters: {
      '@media screen and (max-width:780px)': {
        display: 'none',
      },
    },
    mobileFilters: {
      display: 'none',
      '@media screen and (max-width:780px)': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    filterList: {
      '@media screen and (max-width:780px)': {
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
    filterDialog: {
      maxWidth: 360,
      margin: 'auto',
    },
    filterDialogClose: {
      position: 'absolute',
      right: 20,
      cursor: 'pointer',
    },
    filterTitle: {
      paddingLeft: 16,
      fontWeight: 'bold',
      display: 'block',
      marginBottom: 10,
      fontSize: 18,
    },
  });
  const classes = useStyle();

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    <S.Wrapper>
      {loaded ? <div className={classes.container}>
        {missions && Boolean(missions.length) ? (
          <S.MissionsList>
            {(missions || []).map((mission) => {
              return <div ref={ref} key={mission.mission_id}>
                <Mission
                  key={mission.mission_id}
                  data={mission}
                  className={classes.mission}
                  is_search={!props.search}
                  role={props.role}
                />
              </div>
            })}
          </S.MissionsList>
        ) : missions && Array.isArray(missions) && (
          <NoResults />
        )}
      </div> : (
        <div>Chargement</div>
      )}
    </S.Wrapper>
  );
};

export default MissionsList;

let S = {};

S.Wrapper = styled.div`
  
`

S.MissionsList = styled.div`
  display:grid;
  grid-template-columns:repeat(2, minmax(300px, 1fr));
  gap: 30px; 
  width: 100%;
  align-items: stretch;

  @media (max-width: 1450px) {
    grid-template-columns: 1fr;
  }
`