//
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Steps from '../components/Steps/Steps';
import Api from '../contexts/Api';
import { useNotification } from '../components/UseNotification';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import styled from 'styled-components';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Register = (props) => {
  const [userType, setUserType] = useState(0);
  const { notification } = useNotification();
  const [signIn, setSignIn] = useState();
  const [error, setError] = useState(false);

  const handleUserType = (e) => {
    setUserType(parseInt(e));
  };

  const isDriver = userType === 1;
  const isAdmin = props.from_admin;

  let formFields = [
    {
      fields: [
        { component: 'register_custom', name: 'user_type', label: "Vous êtes...", handleChange: handleUserType, required: true },
        { type: 'text', component: 'text', name: 'firstname', label: 'Prénom', size: 'calc(50% - 10px)', cond: isDriver, required: true },
        { type: 'text', component: 'text', name: 'name', label: 'Nom', size: 'calc(50% - 10px)', cond: isDriver, required: true },
        { type: 'text', component: 'text', name: 'phone', label: 'Numéro de téléphone', size: 'calc(50% - 10px)', required: true },
        { type: 'email', component: 'text', name: 'email', label: 'Email', size: 'calc(50% - 10px)', required: true },
        { type: 'password', component: 'text', name: 'password', label: 'Mot de passe', required: true },
        { type: 'text', component: 'text', name: 'siret', label: 'Numéro de siret', required: true },
        { type: 'text', component: 'text', name: 'address', label: 'Adresse', required: true },
        { type: 'text', component: 'text', name: 'cp', label: 'Code postal', size: 'calc(50% - 10px)', required: true },
        { type: 'text', component: 'text', name: 'city', label: 'Ville', size: 'calc(50% - 10px)', required: true },
        { component: 'checkbox', name: 'contract', label: "J'accepte les termes du contrat (<a href='/assets/files/contrat-chauffeur.pdf' download>télécharger le contrat</a>)", cond: isDriver, required: true },
        { component: 'checkbox', name: 'cgv', label: "J'accepte les <a target='blank' href='http://convoyage.saint-bernard-services.com/conditions-generales-de-vente/'>CGV</a>", cond: !isDriver, required: true },
      ],
    },
  ];

  const companyField = {
    type: 'text',
    component: 'text',
    name: 'company',
    label: 'Nom de la société',
    required: true
  };

  if (!isDriver) {
    formFields[0].fields.splice(1, 0, companyField);
  }

  if (isDriver) {
    formFields.push(
      {
        fields: [
          { type: 'text', component: 'text', name: 'driver_licence_id', label: 'Numéro du permis', size: 'calc(50% - 10px)', required: true },
          { type: 'date', component: 'datepicker', name: 'driver_licence_date', label: "Date d'obtention", size: 'calc(50% - 10px)', required: true },
          { type: 'text', component: 'text', name: 'driver_licence_place', label: "Lieu d'obtention", size: 'calc(50% - 10px)', required: true },
          { type: 'text', component: 'text', name: 'driver_licence_prefecture', label: 'Préfecture ayant délivré le permis', size: 'calc(50% - 10px)', required: true },
        ],
      },
      {
        fields: [
          { type: 'text', component: 'file', name: 'driver_license_file', label: 'Permis de conduire*', size: 'calc(50% - 10px)', required: true },
          { type: 'text', component: 'file', name: 'assurance_file', label: 'Assurance W Garage', size: 'calc(50% - 10px)' },
          { type: 'text', component: 'file', name: 'car_registration_file', label: "Carte d'identité*", size: 'calc(50% - 10px)', required: true },
          { type: 'text', component: 'file', name: 'civil_liability_file', label: 'Responsabilité civile', size: 'calc(50% - 10px)' },
          { type: 'text', component: 'file', name: 'rib_file', label: 'RIB*', size: 'calc(50% - 10px)', required: true },
          { type: 'text', component: 'file', name: 'criminal_record_file', label: 'Contrat signé*', size: 'calc(50% - 10px)', required: true },
        ],
      }
    );
  }

  if (userType === 0) {
    formFields = [
      {
        fields: [
          { component: 'register_custom', name: 'user_type', label: "Vous êtes...", handleChange: handleUserType, required: true },
        ],
      },
    ];
  }

  const stepsLabel = isDriver
    ? ['Données personnelles', 'Permis de conduire', 'Documents']
    : ['Données personnelles'];

  const handleChange = async (data) => {
    setSignIn(false);
    //
    // ─── PREPARE DATA FOR API REQUEST BODY ───────────────────────────────────────
    //
    const files = [
      'driver_license_file',
      'assurance_file',
      'car_registration_file',
      'civil_liability_file',
      'rib_file',
      'criminal_record_file',
    ];
    const formData = new FormData();

    files.forEach((file) => {
      if (data[file]) {
        formData.append('files[]', data[file], `${file}-${data[file].name}`);
        delete data[file];
      }
    });
    formData.append('fields', JSON.stringify(data));

    const params = {
      data: formData,
      endpoint: '/auth/signup',
      method: 'POST',
      removeHeader: true,
    };
    const createUser = await Api(params);

    //
    // ─── SHOW SUCCESS NOTIFICATION ON REGISTER ───────────────────────────────────────
    //
    if (isAdmin) {
      notification({
        variant: createUser.success ? 'success' : 'error',
        message: createUser.message,
      });
      setSignIn(true);
    }

    //
    // ─── ON SUCCESS WE USE REDUX TO LOG USER ───────────────────────────────────────
    //
    if (createUser.success && createUser.access_token && !isAdmin) {
      setSignIn(true)
    } else if (!createUser.success) {
      setError(true);
    }
  };

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      minHeight: !isAdmin && '100vh',
    },
    form: {
    },
    signin: {
      position: 'absolute',
      top: 20,
      right: 20,
      color: '#000',
      textDecoration: 'none',
    },
    loader: {
      width: '70px !important',
      height: '70px !important',
      margin: 'auto',
    },
    errorContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: 'auto',
    },
    errorImage: {
      marginTop: 40,
      width: 340,
    },
    errorBtn: {
      width: 300,
      marginTop: '40px !important',
    },
    radioButton: {
      color: 'red'
    }
  });
  const classes = useStyle();

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    <div className={classes.container}>
      <S.EstimationButton variant="outline" buttonSize="icon" onClick={() => { document.querySelector('.estimation_modal').classList.add('estimation_modal--open') }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="26" viewBox="0 0 16 26" fill="none">
          <path d="M2.405 12.1875C2.4 12.3246 2.4 12.4566 2.4 12.5938V13.4062C2.4 13.5434 2.4 13.6754 2.405 13.8125H1.6C0.715 13.8125 0 14.5387 0 15.4375C0 16.3363 0.715 17.0625 1.6 17.0625H3.015C4.495 21.323 8.5 24.375 13.2 24.375H14.4C15.285 24.375 16 23.6488 16 22.75C16 21.8512 15.285 21.125 14.4 21.125H13.2C10.305 21.125 7.79 19.4797 6.505 17.0625H12.8C13.685 17.0625 14.4 16.3363 14.4 15.4375C14.4 14.5387 13.685 13.8125 12.8 13.8125H5.61C5.605 13.6805 5.6 13.5434 5.6 13.4062V12.5938C5.6 12.4566 5.605 12.3195 5.61 12.1875H12.8C13.685 12.1875 14.4 11.4613 14.4 10.5625C14.4 9.66367 13.685 8.9375 12.8 8.9375H6.505C7.79 6.52031 10.305 4.875 13.2 4.875H14.4C15.285 4.875 16 4.14883 16 3.25C16 2.35117 15.285 1.625 14.4 1.625H13.2C8.5 1.625 4.495 4.67695 3.015 8.9375H1.6C0.715 8.9375 0 9.66367 0 10.5625C0 11.4613 0.715 12.1875 1.6 12.1875H2.405Z" fill="currentColor" />
        </svg>
        <span>Estimation</span>
      </S.EstimationButton>
      {!signIn && signIn !== false && (
        <form name="myForm" className={classes.form}>
          <Steps
            formFields={formFields}
            stepsLabel={stepsLabel}
            title={`Inscription ${userType === 1 ? 'chauffeur' : userType === 2 ? 'garage' : ''}`}
            handleChange={handleChange}
            userType={userType}
          />
        </form>
      )}
      {signIn === false && !error && <CircularProgress className={classes.loader} />}
      {/* {!isAdmin && (
        <Link className={classes.signin} to="/connexion">
          Connexion
        </Link>
      )} */}
      {signIn && !error && isAdmin && (
        <div className={classes.errorContainer}>
          <img src="/assets/img/step_final.svg" alt="Étape finale" className={classes.errorImage} />
          <Button
            variant="contained"
            className={classes.errorBtn}
            onClick={() => window.location.reload()}
          >
            Recharger
          </Button>
        </div>
      )}
      {signIn && !isAdmin && (
        <div className={classes.errorContainer}>
          <img src="/assets/img/registred.svg" alt="Étape finale" className={classes.errorImage} style={{ marginBottom: 40 }} />
          <p>Votre compte a été créé, un administrateur le validera dans les plus brefs délais</p>
        </div>
      )}
      {error && (
        <div className={classes.errorContainer}>
          <img src="/assets/img/error.svg" alt="Erreur" className={classes.errorImage} />
          <Button
            variant="contained"
            className={classes.errorBtn}
            onClick={() => window.location.reload()}
          >
            Erreur veuillez recharger
          </Button>
        </div>
      )}
    </div>
  );
};

export default Register;

const S = {};

S.EstimationButton = styled(Button)`
  position: fixed !important;
  top:20px;
  left:20px;
  background:white !important;
  border:2px solid var(--secondary-color) !important;
  padding:10px !important;
  span{
    margin-left: 15px;
  }
`