import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { isEmptyArray, isEmptyNestedObject, useOutsideClick } from '../../contexts/Utils';
import Api from '../../contexts/Api';
import { Link, useLocation } from 'react-router-dom';

const SearchBarHome = ({ widthSearch = 480 }) => {
  const [inputSearch, setInputSearch] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [empty, setEmpty] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const user_id = JSON.parse(localStorage.getItem('saint_bernard_auth')).user_id;

  const dropdownRef = useOutsideClick(() => setShowSuggestions(false));

  const location = useLocation();

  // init on change location
  useEffect(() => {
    clear();
  }, [location]);

  // 
  // ─── HANDLE CLICK ON INPUT ───────────────────────────────────────
  //
  const handleClick = () => {
    if (inputSearch.length > 2) {
      setShowSuggestions(true);
    }
  }

  // 
  // ─── HANDLE CHANGE INPUT SEARCH ───────────────────────────────────────
  //
  const handleChangeInputSearch = async (e) => {
    const value = e.target.value;
    setInputSearch(value);

    if (!value || !value.length) {
      // Clear previous typing timeout to avoid the call of the last stored getSuggestions function
      clearTimeout(typingTimeout);
      return clear();
    }

    // Clear previous typing timeout
    clearTimeout(typingTimeout);

    const newTimeout = setTimeout(() => {
      // API call
      if (value.length > 2) getSuggestions(value);
      else {
        setShowSuggestions(false);
        setSuggestions([]);
      }
    }, 500);

    setTypingTimeout(newTimeout);
  }

  // 
  // ─── GET SUGGESTIONS ───────────────────────────────────────
  //
  const getSuggestions = async (value) => {
    const getSuggestion = await Api({ endpoint: `/search?user_id=${user_id}&search=${value}&location=/${location.pathname.split("/")[1]}`, method: 'GET' });
    setShowSuggestions(true);
    if (getSuggestion.success && !isEmptyNestedObject(getSuggestion.data)) {
      setSuggestions(getSuggestion.data);
      setEmpty(false)
    }
    else setEmpty(true)
  }

  // 
  // ─── CLEAR INPUTS & SUGGESTIONS & SEARCHQUERY ───────────────────────────────────────
  //
  const clear = () => {
    setInputSearch('');
    setSuggestions([]);
    setShowSuggestions(false);
  }

  // Tableau associatif
  const keyNames = {
    missions: 'Missions',
    users: 'Utilisateurs',
    events: 'Événements',
  }

  // Component global for better code and readability
  const CommonContent = ({ suggestion }) => (
    <>
      <span className='highlight'></span>
      <span className='label'>{suggestion.label}</span>
      <span className='description'>{suggestion.description}</span>
      {/* <S.Edit to={`/${suggestion.url}/${suggestion.id}/editer`} onClick={clear}>
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" stroke-linecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" /><path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" /><path d="M16 5l3 3" /></svg>
      </S.Edit> */}
    </>
  );

  return (
    <S.SearchBar $width={widthSearch}>
      <S.Icon>
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M21 21l-6 -6" /></svg>
      </S.Icon>
      <input value={inputSearch} type="text" placeholder='Rechercher une mission, un garage, un convoyeur' onChange={handleChangeInputSearch} onClick={handleClick} />
      {Boolean(inputSearch.length) && <svg onClick={clear} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>}

      <S.Dropdown show={showSuggestions} ref={dropdownRef}>
        {!empty ? Object.entries(suggestions).map(([key, value]) => {
          return !isEmptyArray(value) && <div>
            <S.TitleContainer>
              <S.Title>{keyNames[key]}</S.Title>
              <S.Counter>{value.length}</S.Counter>
            </S.TitleContainer>
            <S.SuggestionsContainer>
              {value.map((suggestion) => {
                const url = `${suggestion.url}/${suggestion.id}`;
                return <S.SuggestionLink to={url} onClick={clear}>
                  <CommonContent suggestion={suggestion} />
                </S.SuggestionLink>
              })}
            </S.SuggestionsContainer>
          </div>
        }) : <div>Aucun résultat</div>}
      </S.Dropdown>
    </S.SearchBar>
  );
};

export default SearchBarHome;

const S = {};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

S.SearchBar = styled.div`
  display:flex;
  position: relative;
  align-items:center;
  width: ${({ $width }) => `${$width}px`};
  height: 40px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  background: white;
  padding: 0 10px;
  gap: 10px;

  & input {
    border: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;
    background: transparent;

    &:focus {
      outline: none;
    }
  }
`;

S.Icon = styled.div`
  font-size: 1.2em;
  color:var(--primary-color);
`

S.Dropdown = styled.div`
  display: ${({ show }) => show ? 'flex' : 'none'};
  position: absolute;
  top: 100%;
  left: 0;
  box-sizing: border-box;
  margin-top: 5px;
  flex-direction: column;
  gap: 10px;
  padding: 16px 18px;
  border-radius: 7px;
  background: white;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  color: black;
  max-height: 200px;
  overflow-y: scroll;
  z-index: 10;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  animation: ${fadeIn} 0.3s ease forwards;

  & > div {
    cursor: pointer;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.1);
    border-radius: 5px;
  }
`

S.TitleContainer = styled.div`
  display: flex;
  gap: 5px;
  color: #252525;
  font-weight: 700;
`

S.Title = styled.div`
  font-size: 18px;
  opacity: 0.3;
  text-transform: capitalize;
`

S.Counter = styled.div`
  display: flex;
  padding: 2px 9px;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  border: 1px solid #C9C9C9;
  background: #F6F6F6;
  opacity: 0.4;
  font-size: 12px;
`

S.SuggestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  color: #252525;
  margin-top: 10px;
`

S.SuggestionLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding: 5px 0;
  text-decoration: none;
  color: #323232;

  & .label {
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .description {
    opacity: 0.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .highlight {
    visibility: hidden;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--primary-color);
    opacity: 1;
  }

  &:hover .highlight {
    visibility: visible;
  }
    
`

S.Edit = styled(Link)`
  margin-left: auto;
  margin-right: 0;

  & svg {
    font-size: 14px;
    color: #252525;    
  }
`