import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getValue, prepareFormData } from "../contexts/Utils";
import Formfields from "./Formfields";
import Button from "./BaseComponents/Button";
import Api from "../contexts/Api";
import TitleSeparator from "./BaseComponents/TitleSeparator";

const EventsManage = (props) => {
  const defaults = props.default || {}

  const [duo, setDuo] = useState(getValue(props, ['default', 'events_duos']) || [{}]);
  const [duoData, setDuoData] = useState({});
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const user_id = JSON.parse(localStorage.getItem('saint_bernard_auth')).user_id;

  //
  // ─── HANDLE DRIVER SEARCH ───────────────────────────────────────
  //
  const handleUserSearch = async (opt) => {
    let value = opt.value;

    const params = {
      endpoint: `/users?user_id=${user_id}&search=${value}&fields=name,firstname,user_id,email&user_type=1&columns=name,firstname`,
      method: 'GET',
    };

    const getUsers = await Api(params);
    if (getUsers.success) {
      const formatData = getUsers.data.map((value) => {
        return { label: `${value.firstname} ${value.name} (${value.email})`, id: value.user_id };
      });
      setUsers(formatData);
    }
  };

  //
  // ─── HANDLE EVENT CREATE ───────────────────────────────────────
  //
  const handleChange = async () => {
    let data = prepareFormData({ formId: 'event-form' });

    const params = {}

    if (props.update) {
      data.fields.event_id = defaults.event_id
      params.event_id = defaults.event_id
    }

    data.fields.events = []
    Object.entries(data.fields).map(([key, value]) => {
      if (key.startsWith('event-')) {
        const keys = key.split('-');
        if (value) {
          let finalValue = value;
          if (['price', 'id'].includes(keys[2])) finalValue = parseInt(value)
          data.fields.events[keys[1]] = { ...data.fields.events[keys[1]], [keys[2]]: finalValue }
        }
        delete data.fields[key];
      }
    })

    data.fields.time_event = new Date(data.fields.time_event).toISOString();
    params.fields = { ...params.fields, ...data.fields }

    await Api({ endpoint: '/events', method: props.update ? 'PUT' : 'POST', data: { ...params } })
    navigate(`/evenements`);
  }

  const updateDuos = (id, index) => {
    setDuoData({ ...duoData, [index]: id })
  }

  return <>
    <S.Wrapper>
      <S.Form id="event-form">
        <TitleSeparator title="Type d'événement" />
        <S.Flex>
          <Formfields field={{ component: 'text', default: defaults.label, name: 'label', label: "Type d'événement", error: getValue(errors, ['origin_additional_address', 'message']) }} />
          <Formfields field={{ type: 'date', component: 'datepicker', name: 'time_event', defaultValue: defaults.time_event ? defaults.time_event.split('T')[0] : '', label: "Date de l'événement", required: true, error: getValue(errors, ['time_event', 'message']) }} />

        </S.Flex>
        <TitleSeparator title="Lieu de l'événement" />
        <Formfields field={{ component: 'text', default: defaults.address, name: 'address', label: "Adresse", error: getValue(errors, ['origin_additional_address', 'message']) }} />
        <S.Flex>
          <Formfields field={{ component: 'text', name: "postal_code", label: "Code postal", default: defaults.postal_code, required: true }} />
          <Formfields field={{ component: 'text', name: "city", label: "Ville", default: defaults.city, required: true }} />
        </S.Flex>
        <S.Flex>
          <Formfields field={{ component: 'text', name: "referent_name", default: defaults.referent_name, label: "Contact sur le lieu de l'événement", required: true }} />
          <Formfields field={{ component: 'text', name: "phone", default: defaults.phone, label: "Téléphone", required: true }} />
        </S.Flex>
        <Formfields field={{ component: 'text', name: "details", default: defaults.details, label: "Informations complémentaires", required: true }} />
        <S.FlexBetween>
          <TitleSeparator title="Informations sur l'événement" />
          <S.Button onClick={() => setDuo([...duo, {}])}>Ajouter un binôme</S.Button>
        </S.FlexBetween>
        {duo.map((value, index) => (
          <S.DuoCard key={index}>
            <span>Binôme {index + 1}</span>
            <S.Flex>
              <Formfields field={{ component: 'search', name: 'driver', label: 'Définir un convoyeur', data: users, default: value.driver_id && { label: `${value.users.firstname} ${value.users.name} (${value.users.email})`, id: value.driver_id }, handleSearch: handleUserSearch, handleChange: (e) => updateDuos(getValue(e, ['value', 'id']), index) }} />
              {value.id && <div style={{ display: 'none' }}><Formfields field={{ type: 'hidden', component: 'text', name: `event-${index}-id`, hidden: true, value: value.id }} /></div>}
              <div style={{ display: 'none' }}><Formfields field={{ type: 'hidden', component: 'text', name: `event-${index}-driver_id`, hidden: true, default: duoData[index] || value.driver_id }} /></div>
              <Formfields field={{ component: 'text', type: "time", default: value.time_start, name: `event-${index}-time_start`, label: "Horaire d'arrivée", required: true }} />
              <Formfields field={{ component: 'text', type: "time", default: value.time_end, name: `event-${index}-time_end`, label: "Horaire de départ", required: true }} />
              <Formfields field={{ component: 'text', default: value.price, name: `event-${index}-price`, label: "Prix", required: true }} />
            </S.Flex>
          </S.DuoCard>))}
      </S.Form >
    </S.Wrapper>
    <S.HandleChangeButton onClick={handleChange} tag='div'>Enregistrer</S.HandleChangeButton>
  </>
};

export default EventsManage;

const S = {};

S.Wrapper = styled.div`
  box-shadow: 0 20px 27px 0 rgb(0 ,0, 0, 5%);
  border-radius: 10px;
  overflow: hidden;
`;

S.DuoCard = styled.div`
  display: flex;
  flex-direction:column;
  gap:20px;
  div{
    display: flex;
    gap:20px;
  }
`

S.Flex = styled.div`
  display: flex;
  gap:20px;
  &>div{
    flex:1;
  }

  @media (max-width: 1300px) {  
    flex-direction: column;
  }
`

S.FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:20px;

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

S.Form = styled.form`
  background:white;
  padding:20px;
  display: flex;
  flex-direction: column;
  gap:20px;
`

S.Button = styled(Button)`
  min-width:fit-content;
`

S.HandleChangeButton = styled(Button)`
  /* display: block; */
  margin: 20px 0 0 auto;
  width:fit-content;
`