import styled from "styled-components";
import TitleSeparator from "../BaseComponents/TitleSeparator";
import Button from "../BaseComponents/Button";
import { useEffect, useState } from "react";
import Api from "../../contexts/Api";
import Modal from "../BaseComponents/Modal";
import Dropzone from "../BaseComponents/Dropzone";

const MissionFiles = (props) => {

  const [missionFiles, setMissionFiles] = useState([]);
  const [parentFiles, setParentFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loadFiles, setLoadFiles] = useState(null);
  const readOnly = props.readonly

  useEffect(() => {
    const fetchData = async () => {
      const getMissionsFiles = await Api({ endpoint: `/missions/files?mission_id=${props.mission_id}`, method: 'GET' });
      setMissionFiles(getMissionsFiles.data)
      setLoadFiles(false);
    }
    if (loadFiles === null || loadFiles) fetchData()
  }, [loadFiles])

  // 
  // ─── GET DROPZONE FILES ───────────────────────────────────────
  //
  const handleFilesChange = (files) => {
    setParentFiles(files);
  };

  // 
  // ─── ADD NEW FILES ───────────────────────────────────────
  //
  const handleFiles = async () => {
    const formData = new FormData();
    parentFiles.forEach((file) => formData.append(`files[]`, file));
    formData.append('mission_id', props.mission_id)
    await Api({ endpoint: '/missions/files', data: formData, removeHeader: true });
    setShowModal(false);
    setLoadFiles(true);
  }

  // 
  // ─── GET SPECIFIC FILE ───────────────────────────────────────
  //
  const handleGetFiles = async (id, filename) => {
    const query = await Api({ endpoint: `/missions/file?id=${id}&filename=${filename}&mission_id=${props.mission_id}`, method: 'GET' });
    if (query.success && query.data) {
      const file = query.data;
      const link = document.createElement('a');
      link.download = file.name;
      link.href = "data:application/pdf;base64," + file.buffer;
      link.click();
    }
  }

  // 
  // ─── DELETE FILE ───────────────────────────────────────
  //
  const handleDeleteFile = async (e, id, filename) => {
    e.stopPropagation();
    await Api({ endpoint: '/missions/file', method: 'DELETE', data: { id, filename, mission_id: props.mission_id } });
    setLoadFiles(true);
  }

  return <>
    <S.FileContainer>
      <S.FileTitle>
        <TitleSeparator title="Documents" />
        {!readOnly && <S.AddFileBtn onClick={() => setShowModal(true)}>Ajouter des documents</S.AddFileBtn>}
      </S.FileTitle>
      {Boolean(missionFiles.length) && <S.FileContent>
        {missionFiles.map((file) => {
          return <S.File onClick={() => handleGetFiles(file.id, file.filename)}>
            <img src="/assets/img/file.png" alt="" />
            <span>{file.filename}</span>
            {!readOnly && <S.FileDelete onClick={(e) => handleDeleteFile(e, file.id, file.filename)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 80h-82.4l-34-56.7A48 48 0 0 0 274.4 0H173.6a48 48 0 0 0 -41.2 23.3L98.4 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16l21.2 339a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128h16a16 16 0 0 0 16-16V96a16 16 0 0 0 -16-16zM173.6 48h100.8l19.2 32H154.4zm173.3 416H101.1l-21-336h287.8z" /></svg>
            </S.FileDelete>}
          </S.File>
        })}
      </S.FileContent>}
    </S.FileContainer>
    <Modal title="Ajouter des documents" show={showModal} handleClose={() => setShowModal(false)}>
      <Dropzone onFilesChange={handleFilesChange} multiple={true} title="Importer mes fichiers" />
      <S.Button onClick={handleFiles}>Enregistrer</S.Button>
    </Modal>
  </>
}

export default MissionFiles;

const S = {};

S.FileContainer = styled.div`
  background:white;
  box-shadow: 0 20px 27px 0 rgb(0 ,0, 0, 5%);
  border-radius: 10px;
  margin-top:20px;
  display: flex;
  flex-direction: column;
  gap:20px;
  padding:20px;
  
`

S.FileTitle = styled.div`
  display: flex;
  gap:20px;
`

S.FileContent = styled.div`
  display: flex;
  gap:40px;
  flex-wrap: wrap;
`

S.File = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
    img{
    width: 60px;
  }
`

S.FileDelete = styled.span`
  width:20px;
  position: absolute;
  top: -10px;
  left:50%;
  transform: translateX(40px);
  fill:var(--error);
  z-index: 1;
`

S.AddFileBtn = styled(Button)`
  min-width: fit-content;
  background:var(--light-grey);
  color:black;
`

S.Button = styled(Button)`
  margin-left: auto;
  width:fit-content;
  margin-top: 20px;
`