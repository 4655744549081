import { Link, NavLink } from "react-router-dom";
import styled from "styled-components"
import TitleSeparator from "./BaseComponents/TitleSeparator";

const Sidebar = (props) => {

  const userRole = props.user.role;
  const userDisabled = props.user.disabled;
  const userStatus = props.user.status;

  return <S.Sidebar>
    <Link to="/">
      <img className="logo" src="/assets/img/logo.png" alt="Logo saint bernard" />
    </Link>
    <S.Navigation>
      <ul>
        <li>
          {!userDisabled && Boolean(userStatus) && <NavLink to="/" end>Accueil</NavLink>}
        </li>
      </ul>
      <TitleSeparator title='Missions' />
      <ul>
        <li>
          {!userDisabled && Boolean(userStatus) && <NavLink to="/missions" end>
            {['garage'].includes(userRole) ? 'Mes convoyages' : 'Toutes les missions'}
          </NavLink>}
        </li>
        {['driver'].includes(userRole) && !userDisabled && Boolean(userStatus) && (
          <li><NavLink to="/mes-missions">Mes missions</NavLink></li>
        )}
        {['super-admin', 'garage'].includes(userRole) && !userDisabled && Boolean(userStatus) && (
          <li><NavLink to="/mission-en-attente">Convoyage en attente</NavLink></li>
        )}
        {Boolean(userStatus) && <li><NavLink to="/historique">Historique</NavLink></li>}
      </ul>
      {['super-admin', 'driver'].includes(userRole) && <>
        <TitleSeparator title='Événements' />
        <ul>
          {!userDisabled && <>
            <li><NavLink to="/evenements">Tous les événements</NavLink></li>
            <li><NavLink to="/evenements-en-cours">En cours</NavLink></li>
          </>}
          <li><NavLink to="/historique-evenements">Historique</NavLink></li>
        </ul>
      </>}
      {['super-admin'].includes(userRole) && <>
        <TitleSeparator title='Utilisateurs' />
        <ul>
          <li><NavLink to="/utilisateurs">Utilisateurs</NavLink></li>
          <li><NavLink to="/penalites">Pénalités</NavLink></li>
        </ul>
      </>}

    </S.Navigation>

    <div>
      {['garage', 'super-admin'].includes(userRole) && <S.LegalInfos>
        <a href="https://convoyage.saint-bernard-services.com/cgv/" target="_blank" rel="noreferrer">CGV</a>
        <a href="https://convoyage.saint-bernard-services.com/cgu/" target="_blank" rel="noreferrer">CGU</a>
      </S.LegalInfos>}
      <S.ActuBanner>
        <img src="/assets/img/banner.png" alt="bannière" />
      </S.ActuBanner>
    </div>
  </S.Sidebar>
}

export default Sidebar

const S = {};

S.Sidebar = styled.div`
  width:200px;
  position: fixed;
  top:20px;
  bottom:20px;
  left:20px;
  background: white;
  padding:10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 20px 27px 0 rgb(0 ,0, 0, 5%);
  border-radius: 10px;

  .logo{
    display: block;
    margin:20px auto 0;
    width:150px;
  }
  @media screen and (max-width:900px){
    display: none;
  }
`

S.Navigation = styled.nav`
  ul{
    padding:0 0 0 15px;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap:15px;
    margin-bottom: 40px;
    li{
      a{
        color:#2D2D2D;
        text-decoration: none;
      }
    }
  }
`

S.ActuBanner = styled.div`
  height:150px;
  border-radius:8px;
  overflow: hidden;
  img{
    width:100%;
    height:100%;
    object-fit:cover;
  }
`

S.LegalInfos = styled.div`
  display: flex;
  gap:20px;
  justify-content: center;
  margin-bottom: 10px;
  a{
    color:var(--primary-color);
    text-decoration: none;
  }
`