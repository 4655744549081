import styled from 'styled-components';

// 
// ─── STATUS ───────────────────────────────────────
//

export const Status = styled.span`
  background:${({ $status, $colors }) => $colors[$status].background};
  color:${({ $status, $colors }) => $colors[$status].color};
  display: block;
  width:fit-content;
  padding:6px;
  text-align: center;
  border-radius: 5px;
  font-size: 14px;
  justify-self: center;
`