import styled from "styled-components"
import { hasNotif } from "../../contexts/Utils";

const Notifications = ({ user }) => {
  const notifs = hasNotif(user.role);

  //if (user.role === 'super-admin') return

  return <S.Container>
    {notifs.map((notif, i) => { return <S.Notification ><span>{notif.value}</span></S.Notification> })}
  </S.Container>
}

export default Notifications

const S = {};

S.Container = styled.div`
  display: flex;
  background:#EFEFEF;
  width:100%;
  gap:20px;
  padding-top: 20px;
  margin-top: -20px;
`

S.Notification = styled.div`
  width:100%;
  display: flex;
  gap:8px;
  flex: 1;
  height:100%;
  background:#393939;
  color:white;
  padding:10px;
  border-radius: 6px;
  span{
    margin:auto;
  }
`