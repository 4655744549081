// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Button from '../BaseComponents/Button';
import CloseIcon from '@mui/icons-material/Close';
import Formfields from '../Formfields';
import GeoSearch from '../GeoSearch';
import { getValue, prepareFormData, getPrice, formatDistanceTime } from '../../contexts/Utils';
import Api from '../../contexts/Api';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EstimationModal = (props) => {
  const [formData, setFormData] = useState({});
  const [vehicleType, setVehicleType] = useState(1);

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      display: 'none',
      zIndex: 1000,
      position: 'fixed',
      inset: 0,
    },
    container: {
      background: 'white',
      boxShadow: ' 0 20px 27px 0 rgb(0 ,0, 0, 5%)',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(calc(-50% + 120px), -50%)',
      padding: 20,
      width: 340,
      overflowY: 'auto'
    },
    shadow: {
      position: 'fixed',
      inset: 0,
      background: 'rgba(0,0,0,0.6)',
    },
    dialogClose: {
      position: 'absolute',
      top: 20,
      right: 20,
      cursor: 'pointer',
    },
    geoFields: {
      '&>div': {
        marginBottom: 20
      }
    },
    formSubmit: {
      marginTop: 20
    },
    content: {
      marginTop: 40
    }
  })

  const classes = useStyle();

  //
  // ─── HANDLE GEO SEARCH FOR TRAVEL ───────────────────────────────────────
  //
  const handleGeoSearch = async () => {
    let data = prepareFormData({ formId: 'additional' });
    let addresses = prepareFormData({ formId: 'addresses-form-valuation' });

    let fields = data.fields;

    let geoApiData = {
      origin: [],
      destination: [],
    };

    //
    // ─── PREPARE ORIGIN AND DESTINATION DATA ───────────────────────────────────────
    //
    let localisationFields = ['address', 'cp', 'city'];
    let localisationType = ['valuation_origin', 'valuation_destination'];

    localisationType.map((type) => {
      localisationFields.map((field) => {
        let value = addresses.fields[`${type}_${field}`];
        if (addresses.fields[`${type}_${field}`]) {
          geoApiData[type === 'valuation_origin' ? 'origin' : 'destination'].push(value);
        }
      });
    });


    //
    // ─── GOOGLE DISTANCE API CALL ───────────────────────────────────────
    //
    const params = {
      endpoint: '/map',
      data: geoApiData,
    };
    const getTravelInfos = await Api(params);

    let distanceData = getTravelInfos.data;
    if (distanceData) {
      const formatedGeo = {
        insurance: fields.insurance,
        special_plate: fields.special_plate,
        easy_to_use: fields.easy_to_use,
        cleaning: fields.cleaning,
        destination_addresses: distanceData.destination_addresses,
        origin_addresses: distanceData.origin_addresses,
        distance:
          (getValue(distanceData, ['rows', 0, 'elements', 0, 'distance', 'value']) || 0) / 1000,
        duration: getValue(distanceData, ['rows', 0, 'elements', 0, 'duration', 'value']),
        vehicle_type: vehicleType
      };
      setFormData(formatedGeo);
    }
  };

  //
  // ─── CHECK IF DATA ARE DEFINED ───────────────────────────────────────
  //
  const checkGeo = formData.origin_addresses && formData.destination_addresses;

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.wrapper + ' estimation_modal'}>
    <div className={classes.shadow}></div>
    <div className={classes.container}>

      <CloseIcon className={classes.dialogClose} onClick={() => { document.querySelector('.estimation_modal').classList.remove('estimation_modal--open') }} />
      <div className={classes.content}>
        <h2>Estimation</h2>
        <form id="addresses-form-valuation" className={classes.geoFields}>
          <Formfields
            field={{
              type: 'select',
              component: 'select',
              name: 'vehicle_type',
              label: 'Type de véhicule',
              dataLabel: 'name',
              dataValue: 'id',
              default: 1,
              data: Object.values({
                "1": {
                  "label": "Véhicule léger (SUV, citadines...)",
                  "value": 1,
                  "place": 0
                },
                "2": {
                  "label": "Véhicule utilitaire (inférieur à 2m)",
                  "value": 2,
                  "place": 0
                },
                "3": {
                  "label": "Véhicule utilitaire (supérieur à 2m)",
                  "value": 3,
                  "place": 0
                }
              }).map((type) => {
                return { id: type.value, name: type.label };
              }),
              handleChange: (e) => { setVehicleType(e) },
            }}
          />
          <GeoSearch name="valuation_origin" label="Adresse de départ" shrink={true} />
          <GeoSearch name="valuation_destination" label="Adresse de livraison" shrink={true} />
        </form>
        <form id="additional">
          <Formfields field={{ component: 'switch', name: 'insurance', label: 'Assurance (20€ HT)' }} />
          <Formfields field={{ component: 'switch', name: 'special_plate', label: 'w-garage (20€ HT)' }} />
          <Formfields field={{ component: 'switch', name: 'cleaning', label: 'Nettoyage extérieur (10€ HT)' }} />
          <Formfields field={{ component: 'switch', name: 'easy_to_use', label: 'Mise en main simple (10€ HT)' }} />
        </form>
        <Button className={classes.formSubmit} onClick={handleGeoSearch}>Estimer</Button>
        {checkGeo && (
          <div>
            <div className={classes.travelTitle}>
              <h2>Résultat</h2>
            </div>
            <div className={classes.travelContainer}>
              <div className={classes.travelInfos}>
                <span>Durée : {formatDistanceTime({ duration: formData.duration })}</span>
              </div>
              <div className={classes.travelInfos}>
                <span>Distance : {formatDistanceTime({ distance: formData.distance })}</span>
              </div>
              <div className={classes.travelInfos}>
                <span>Trajet simple : {getPrice({ formData, partner: props.partner, partner_custom: props.partner_custom, estimation: true })} HT</span>
              </div>
              <div className={classes.travelInfos}>
                <span>Trajet avec reprise : {getPrice({ formData, partner: props.partner, partner_custom: props.partner_custom, recovery: true, estimation: true })} HT</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
};

export default EstimationModal;



// body .MuiList-root {
//   z-index: 1430000;
// }