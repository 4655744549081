import styled from "styled-components"

const TitleSeparator = ({ title }) => {
  return <S.Separator>
    <span>{title}</span>
    <div></div>
  </S.Separator>
}

export default TitleSeparator

const S = {};

S.Separator = styled.div`
  width:100%;
  display: flex;
  gap:8px;
  align-items: center;
  margin:10px 0;
  span{
    white-space: nowrap;
    font-weight: bold;
  }
  div{
    width:100%;
    height:1px;
    background:#000;
  }
`