import styled from "styled-components";
import EventsManage from "../components/EventManage";
import { useEffect, useState } from "react";
import Api from "../contexts/Api";
import { useParams } from "react-router-dom";

const EventsEdit = () => {
  const { event_id } = useParams();
  const [event, setEvent] = useState({});

  //
  // ─── GET EVENT DATAS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const getEvent = await Api({ endpoint: `/events?event_id=${event_id}`, method: "GET", query: { event_id } });
      setEvent(getEvent.data);
    }
    fetchData()
  }, [])

  return Object.keys(event).length && <EventsManage default={event} update={true} />
};

export default EventsEdit;

const S = {};

S.Container = styled.div``;
