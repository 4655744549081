export const PenaltiesList = [
  { label: "Absence lors d'une réservation", name: "missing_from_booking" },
  { label: "Niveau de carburant différent", name: "different_fuel_level" },
  { label: "Pertes de plaques mises à disposition", name: "losses_of_plates" },
  { label: "Dépassement de Kilomètre", name: "exceeding_kilometre" },
  { label: "Absence des documents à remettre au garage", name: "mission_garage_documents" },
  { label: "Non-prise de contact pour les rendez-vous", name: "no_appointments_contact" },
  { label: "Non-respect des horaires de prise de possession du véhicule", name: "vehicle_pickup_time" },
  { label: "Etat des lieux manquant ou incomplet", name: "missing_inventory" },
  { label: "Annulation d'une réservation", name: "cancelation_of_reservation" },
  { label: "Dépassement du temps", name: "exceeding_time" },
  { label: "Un état des lieux doit être réalisé au départ et à l'arrivée via l'application WEPROOV GO ou au format papier en cas d'absence", name: "weproov_inventory" }
]