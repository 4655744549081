import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    auth: {}
  },
  reducers: {
    login: (state, action) => {
      const data = action.payload;
      state.values = action.payload;
      localStorage.setItem('saint_bernard_auth', JSON.stringify({ token: data.access_token, user_id: data.user_id }));
    },
  },
})

// Action creators are generated for each case reducer function
export const { login } = authSlice.actions

export default authSlice.reducer