// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import Api from '../contexts/Api';
import { formatDate, prepareFormData, getValue } from '../contexts/Utils';
import { useNotification } from '../components/UseNotification';
import { PenaltiesList } from '../contexts/Contexts';
import Formfields from '../components/Formfields';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { createUseStyles } from 'react-jss';
import styled from "styled-components";
import { Status } from '../components/StyledComponents';
import NoResults from '../components/BaseComponents/NoResults';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const PenaltyList = (props) => {
  const user_id = JSON.parse(localStorage.getItem('saint_bernard_auth')).user_id;
  const [loaded, setLoaded] = useState(false);
  const [penalties, setPenalties] = useState([]);
  const [errors, setErrors] = useState({});
  const [dialog, setDialog] = useState({ open: false });
  const { notification } = useNotification();
  const role = props.role;

  // 
  // ─── GENERATE DISPLAY PRICE ───────────────────────────────────────
  //
  const genPenaltyPrice = (id) => {
    let price = 0;

    PenaltiesList.forEach(e => {
      if (penalties[id][e.name]) {
        price += parseInt(penalties[id][e.name]);
      }
    })

    return price;
  }

  // 
  // ─── HANDLE DIALOG OPEN AND CLOSE ───────────────────────────────────────
  //
  const handleClickOpen = (e, opt) => {
    e.stopPropagation();

    setDialog({ open: true, ...opt });
  };

  const handleClose = () => {
    setDialog({ open: false });
  };

  // 
  // ─── HANDLE DIALOG UPDATE PENALTY STATUS ───────────────────────────────────────
  //
  const handlePenalty = async () => {
    let data = prepareFormData({ formId: 'status' }) || {};

    if (data.errors) {
      setErrors(data.errors);
      return;
    }

    data.penalty_id = dialog.id

    // set unchecked to 0€
    PenaltiesList.forEach(e => {
      if (!(e.name in data.fields)) {
        data.fields[e.name] = '0'
      }
    })

    const params = { endpoint: '/penalties', method: 'PUT', data }
    const updatePenalty = await Api(params)

    notification({ variant: updatePenalty.success ? 'success' : 'error', message: updatePenalty.message })

    if (updatePenalty.success) {
      handleClose();
      const updatePenalties = { ...penalties };
      updatePenalties[dialog.id] = { ...penalties[dialog.id], ...data.fields };

      setPenalties({ ...updatePenalties })
    }
  }

  // 
  // ─── SEARCH REQUEST TO GET ALL MISSIONS FILTRED BY PARAMS AND FILTERS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchMissions = async () => {

      const getPenalty = await Api({
        endpoint: `/penalties?user_id=${user_id}${role === 'driver' ? `&driver_id=${user_id}` : ''}${['garage', 'super-admin'].includes(role) ? `&company_id=${role === 'garage' ? user_id : 'all'}` : ''}`,
        method: "GET"
      });
      setLoaded(true);

      if (getPenalty.success && getPenalty.data.length) {
        let data = {}
        getPenalty.data.forEach(e => {
          data[e.penalty_id] = e;
        })

        setPenalties(data)
      } else {
        setPenalties([])
      }
    }
    fetchMissions()
  }, [user_id, role])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      margin: '0 auto',

      '& .MuiAccordion-root': {
        boxShadow: " 0 20px 27px 0 rgb(0 ,0, 0, 5%)",
        background: "#FFF",
        padding: '20px 20px 15px',
        marginBottom: 20,
        flexDirection: 'column',

        '&::before': {
          display: 'none',
        },
        '& .MuiAccordionSummary-root': {
          alignItems: 'flex-end',
          paddingBottom: 12,

          '& .MuiAccordionSummary-content': {
            marginBottom: 0,
          }
        }
      }
    },
    accordionSummaryContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    penaltyView: {
      '& p:first-child': {
        color: '#9B9B9B',
        fontSize: '14px',

        '& span': {
          marginLeft: '10px',
          color: '#000'
        }
      },
    },
    penaltyInfos: {
      display: 'flex',
      width: '90%',
      justifyContent: 'space-between',
      alignItems: 'center',
      '@media screen and (max-width:1300px)': {
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '0 20px',
        fontSize: 14
      }
    },
    penaltyDriver: {
      color: 'var(--light-grey)',
      fontSize: '0.9em',
      marginBottom: 10
    },
    value: {
      color: 'var(--primary-color)'
    },
    dialogContent: {
      paddingTop: '20px !important',

      '& .formfield_container:first-child': {
        marginBottom: 20
      }
    }
  })
  const classes = useStyle();

  const StatusColor = {
    1: { color: 'var(--secondary-color)' },
    2: { color: 'black' },
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.wrapper}>
    {loaded ? <>
      {penalties ? <S.TableContainer className="shadow-md rounded-md">
        <S.Table>
          <thead>
            <tr>
              <th>N°</th>
              <th>Prénom</th>
              <th>Nom</th>
              <th>Email</th>
              <th>Pénalité</th>
              <th>Date</th>
              <th>Statut</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {Object.values(penalties).map((penalty) => {
              return (
                <tr key={penalty.penalty_id}>
                  <td>{penalty.mission_ref}</td>
                  <td>{penalty.firstname}</td>
                  <td>{penalty.name}</td>
                  <td>{penalty.email}</td>
                  <td>{genPenaltyPrice(penalty.penalty_id)}</td>
                  <td>{formatDate({ time: penalty.time_create, display: 'date' })}</td>
                  <td><Status $status={penalty.status} $colors={StatusColor}>{getValue(window, ['_DATA', 'penalty_status', penalty.status, 'label'])}</Status></td>
                  <S.Actions>
                    <div onClick={(e) => handleClickOpen(e, { status: penalty.status, id: penalty.penalty_id })}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg></div>
                  </S.Actions>
                </tr>
              );
            })}
          </tbody>
        </S.Table>
      </S.TableContainer>
        :
        <NoResults />}
      <Dialog
        open={dialog.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Modifier la pénalité</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <form name='status'>
            <Formfields field={{
              component: "select", name: "status", label: 'Statut de la pénalité', default: dialog.status, data: Object.values(window._DATA['penalty_status']).map((type) => {
                return { id: type.value, name: type.label }
              }),
            }} />
            {PenaltiesList.map(e => <Formfields key={e.name} field={{ component: 'switch', textName: e.name, label: e.label, size: '100%', textfield: true, textLabel: "Montant (€)", default: ('id' in dialog && penalties[dialog.id][e.name]) || 0, textValue: 'id' in dialog && penalties[dialog.id][e.name], required: true, textError: getValue(errors, [`${e.name}`, 'message']) }} />)}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='error'>Annuler</Button>
          <Button onClick={handlePenalty} variant='contained' autoFocus>Valider</Button>
        </DialogActions>
      </Dialog>
    </> : <div>Chargement</div>}
  </div>
};

export default PenaltyList;

const S = {};

S.Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

S.TableContainer = styled.div`
  max-width: 100%;
  width: 100%;

  @media (max-width: 1300px) {
    overflow-x: scroll;
  }
`;

S.Table = styled.table`
  border-radius: 0.5rem;
  border: 0;
  border-collapse: collapse;
  width: 100%;

  thead {
    background: var(--card-light);
    color: var(--white);
    text-align: left;
  }

  thead > tr > th:first-child {
    border-top-left-radius: 0.5rem;
  }

  thead > tr > th:last-child {
    border-top-right-radius: 0.5rem;
  }

  tbody {
    background: var(--white);
    color: var(--text-color);
  }

  tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 0.5rem;
  }

  tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 0.5rem;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid var(--card-dark);
  }

  th,
  td {
    padding: 1rem;

    &:first-child {
      padding-left: 2rem;
    }

    &:last-child {
      padding-right: 2rem;
    }
  }

  th {
    padding: 1.25rem 1rem;
  }
`;

S.Actions = styled.td`
  & svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: var(--primary-color);
  }
`;