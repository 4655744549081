// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../containers/Login';
import Register from '../containers/Register';
import ForgotPassword from '../containers/ForgotPassword';
import ResetPassword from '../containers/ResetPassword';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Authenticate = () => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/connexion" element={<Login />} />
      <Route path="/inscription" element={<Register />} />
      <Route path="/mot-de-passe-oublie" element={<ForgotPassword />} />
      <Route path="/reinitialiser-le-mot-de-passe/:token" element={<ResetPassword />} />
    </Routes>
  </>
};

export default Authenticate;