/* eslint-disable default-case */
import { useState } from "react";
import { Link } from "react-router-dom";
import { css, styled } from "styled-components";
import Loading from "./Loading";

const Button = (props) => {
  const {
    variant = "primary",
    href,
    tag,
    onClick,
    autoLoading,
    loading: propsLoading,
    children,
    buttonSize,
    noHover,
    disabled,
    className,
    ...rest
  } = props;
  const [loading, setLoading] = useState(false);

  const handleClick = async (e) => {
    if (onClick) {
      let timeout;
      if (autoLoading) {
        timeout = setTimeout(() => setLoading(true), 1000);
      }
      await onClick(e);
      if (autoLoading) {
        setLoading(false);
        clearTimeout(timeout);
      }
    }
  };

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    <S.Button
      as={href ? Link : tag ? tag : "div"}
      to={href}
      $noHover={noHover}
      $variant={variant}
      $size={buttonSize}
      onClick={handleClick}
      disabled={disabled || loading || propsLoading}
      className={`shadow-md ${className}`}
      {...rest}
    >
      {loading || propsLoading ? (
        <Loading loading={loading || Boolean(propsLoading)}>{children}</Loading>
      ) : (
        children
      )}
    </S.Button>
  );
};

const S = {};

S.Button = styled.button`
  padding: 0 25px;
  border-radius: 8px;
  cursor: pointer;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  border:2px solid transparent;
  font-size: 16px;
  color:black;
  transition: all 0.2s ease-in-out;

  ${({ $size }) => {
    switch ($size) {
      case "icon":
        return css`
          aspect-ratio: 1 / 1;
          padding: 0;
        `;
    }
  }}

  ${({ $variant, $noHover }) => {
    switch ($variant) {
      case "primary":
        return css`
          background: #14483C;
          color:white;
        `;

      case "secondary":
        return css`
          background:#89CDA8;
          color: white;
        `;

      case "neutral":
        return css`
          background: var(--white);
          color: var(--primary-color);
          border: 2px solid var(--primary-color);
        `;

      case "destructive":
        return css`
          background: transparent;
          color: var(--error);
          border: 2px solid var(--error);
        `;

      case "error":
        return css`
          background: var(--error);
          color: var(--white);
        `;

      case "outline":
        return css`
          background: var(--white);
          color: var(--primary-color);
          border: 2px solid var(--primary-color);

          ${!$noHover &&
          css`
            &:hover,
            &:focus {
              background: var(--primary-color);
              color: var(--white);
            }
          `}
        `;
    }
  }}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover,
    &:focus {
      opacity: 0.5;
    }
  }

  ${({ $noHover }) =>
    !$noHover &&
    css`
      &:hover,
      &:focus {
        opacity: 0.9;
      }
    `}
`;

export default Button;
