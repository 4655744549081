import { useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const toastOptions = {
    action: key => (
      <>
        <IconButton onClick={() => { closeSnackbar(key) }}>
          <CloseIcon style={{ color: 'white' }} />
        </IconButton>
      </>
    )
  };

  const notification = (opt) =>
    enqueueSnackbar(opt.message, { variant: opt.variant, ...toastOptions });

  return { notification };
}