//
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { prepareFormData } from '../contexts/Utils';
import Api from '../contexts/Api';
import Formfields from '../components/Formfields';
import styled from 'styled-components';
import Button from '../components/BaseComponents/Button';
import { useNotification } from '../components/UseNotification';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Settings = () => {
  const { notification } = useNotification();
  let settingsDefaults = window._DATA.settings;

  //
  // ─── HANDLE SETTINGS ───────────────────────────────────────
  //
  const handleSetting = async ({ formId, setting_id }) => {
    const { fields } = prepareFormData({ formId });

    let params = {
      fields: Object.entries(fields).map(([key, value]) => ({
        name: key,
        value
      }))
    };

    if (setting_id) {
      params.setting_id = setting_id
    }
    const handleSetting = await Api({ endpoint: `/settings`, method: setting_id ? 'PUT' : 'POST', data: { ...params } });

    notification({ variant: handleSetting.success ? 'success' : 'error', message: "Notification modifiée" });
  }

  const formFields = [
    {
      formId: 'notification_driver',
      title: 'Notification chauffeur',
      fields: [
        { component: 'text', name: 'notification_driver', label: "Notification chauffeur" },
      ],
    },
    {
      formId: 'notification_garage',
      title: 'Notification garage',
      fields: [
        { component: 'text', name: 'notification_garage', label: "Notification garage" },
      ],
    },
    {
      formId: 'notification',
      title: 'Notification',
      fields: [
        { component: 'text', name: 'notification', label: "Notification" },
      ],
    },
  ];

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <h1>Paramètres</h1>
    <S.Flex>
      {formFields.map((form, index) => {
        return <S.Form id={form.formId} key={index}>
          <h3>{form.title}</h3>
          {form.fields.map((field) => {
            // check if setting id exists
            const existingSetting = settingsDefaults[field.name];
            return <>
              <Formfields key={field.name} field={{ ...field, default: existingSetting?.value ?? '' }} />
              <Button variant="primary" onClick={() => handleSetting({ formId: form.formId, setting_id: existingSetting?.id })}>Sauvegarder</Button>
            </>
          })}
        </S.Form>
      })}
    </S.Flex>
  </S.Container>
};

export default Settings;


const S = {};

S.Container = styled.div`
  padding: 20px;
  h1 {
    margin-bottom: 20px;
  }
`;

S.Flex = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  gap: 10px;
`

S.Form = styled.form`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  
  h3 {
    margin-bottom: 10px;
  }

  & .shadow-md {
    width: fit-content;
  }
`;